import { useContext, useEffect } from 'react';
import { MainContext } from '../context/MainContext';


const trackPrevPath = (path) => {
  const { prevRoute, setContextData } = useContext(MainContext);
  useEffect(() => () => {
    if (path !== prevRoute) {
      setContextData(context => ({ ...context, prevRoute: path }));
    }
  }, [path]);
};

export default trackPrevPath;
