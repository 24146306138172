var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './UpdateApp.module.scss';
import FullModal from '../FullModal/FullModal';
import Rocket from '../../assets/images/rocket.svg';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import Button from '../Button/Button';
import { AppUpdate } from '@capawesome/capacitor-app-update';
export var UpdateApp = function (_a) {
    var cancelClick = _a.cancelClick;
    return (_jsx(FullModal, __assign({ className: "", show: true }, { children: _jsxs("div", __assign({ className: styles.wrapper }, { children: [_jsxs("div", __assign({ className: styles.content }, { children: [_jsx("img", { src: Rocket }), _jsx("div", __assign({ className: styles.title }, { children: "Time to update!" })), _jsx("div", __assign({ className: styles.desc }, { children: "The current version of the application is no longer supported. For a faster more enhanced experience please update the We Are Invictus App." }))] })), _jsxs(ButtonGroup, { children: [_jsx(Button, { label: "Update now", onClick: function () {
                                void AppUpdate.openAppStore();
                            } }), _jsx(Button, { label: "No, thanks!", secondary: true, onClick: cancelClick })] })] })) })));
};
