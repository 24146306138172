var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { convertToRaw } from 'draft-js';
import { useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import createMentionPlugin from '@draft-js-plugins/mention';
import Editor, { createEditorStateWithText } from '@draft-js-plugins/editor';
import MentionsEntry from './MentionsEntry';
import api from '../../util/api';
import editorStyles from './MentionsEditorChat.module.scss';
import mentionsStyles from './MentionsStylesChat.module.scss';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { decodeEntity } from 'html-entities';
import CommentMention from '../CommentMention/CommentMention';
import { isNative } from '../../util/isNative';
import { Device } from '@capacitor/device';
import TextareaAutosize from 'react-textarea-autosize';
export var editorStateToString = function (state) {
    var contentState = state.getCurrentContent();
    var raw = convertToRaw(contentState);
    return raw.blocks
        .map(function (block) {
        var text = block.text, entityRanges = block.entityRanges;
        var tmp = text;
        entityRanges.reverse().forEach(function (_a) {
            var offset = _a.offset, length = _a.length, key = _a.key;
            var entity = raw.entityMap[key];
            if (entity.type === 'mention') {
                tmp = tmp.slice(0, offset) + "{{".concat(entity.data.mention.id, "}}") + tmp.slice(offset + length);
            }
        });
        return tmp;
    })
        .join('\n');
};
var MentionsEditorChat = function (_a) {
    var disabled = _a.disabled, placeholder = _a.placeholder, editorState = _a.editorState, setEditorState = _a.setEditorState, replyMessage = _a.replyMessage, setReplyMessage = _a.setReplyMessage;
    var ref = useRef(null);
    var textareaRef = useRef(null);
    var _b = useState(false), useTextArea = _b[0], setUseTextarea = _b[1];
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var _d = useState([]), suggestions = _d[0], setSuggestions = _d[1];
    var t = useTranslation().t;
    var _e = useMemo(function () {
        var mentionPlugin = createMentionPlugin({
            theme: mentionsStyles,
            mentionPrefix: '@',
        });
        var MentionSuggestions = mentionPlugin.MentionSuggestions;
        var plugins = [mentionPlugin];
        return { plugins: plugins, MentionSuggestions: MentionSuggestions };
    }, []), MentionSuggestions = _e.MentionSuggestions, plugins = _e.plugins;
    var onOpenChange = useCallback(setOpen, []);
    var onSearchChange = useCallback(function (_a) {
        var value = _a.value;
        var alreadyMentioned = [];
        var content = ref.current.getEditorState().getCurrentContent();
        content.getBlocksAsArray().forEach(function (block) {
            block.findEntityRanges(function (value) {
                var entityKey = value.getEntity();
                if (!entityKey)
                    return false;
                var entity = content.getEntity(entityKey);
                if (entity.getType() === 'mention') {
                    alreadyMentioned.push(entity.getData().mention.name);
                    return true;
                }
                return false;
            }, function (start, end) { });
        });
        if (value.length <= 1000) {
            value !== '' &&
                api.getMentionableUsersForChat(value, alreadyMentioned).then(function (users) {
                    setSuggestions(users);
                });
        }
    }, []);
    var decodeCommentText = function (chat) {
        var matches = [];
        var match = [''];
        var restOfText = chat;
        while (match !== null) {
            restOfText = restOfText.substring(match.index + match[0].length);
            match = restOfText.match('&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-fA-F]{1,6});');
            if (match !== null) {
                matches.push({
                    entity: match[0],
                    index: chat.length - restOfText.length + match.index,
                });
            }
        }
        var returnString = chat;
        matches.forEach(function (elem) {
            returnString = returnString.replace(elem.entity, decodeEntity(elem.entity));
        });
        return returnString;
    };
    var replyMentions = (replyMessage === null || replyMessage === void 0 ? void 0 : replyMessage.mentions) && replyMessage.mentions.length === 0
        ? undefined
        : replyMessage === null || replyMessage === void 0 ? void 0 : replyMessage.mentions;
    var contentReplyMessage = replyMessage === null || replyMessage === void 0 ? void 0 : replyMessage.content;
    var buildReplyComment = function (chatText) {
        if (!replyMentions)
            return [_jsx(_Fragment, { children: chatText })];
        // const { mentionEndingIdentifier, mentionStartingIdentifier } = message;
        var mentionEndingIdentifier = '}}';
        var mentionStartingIdentifier = '{{';
        var tmp = chatText.split(mentionStartingIdentifier);
        var messageParts = tmp.map(function (part) {
            if (part.includes(mentionEndingIdentifier)) {
                var splitLine_1 = part.split(mentionEndingIdentifier);
                var mentionedUser = replyMentions.find(function (user) { return user.id == splitLine_1[0]; });
                return (_jsxs(_Fragment, { children: [_jsx(CommentMention, { className: editorStyles.replyMention, user: mentionedUser }), splitLine_1[1]] }));
            }
            return _jsx(_Fragment, { children: part });
        });
        return messageParts;
    };
    useEffect(function () {
        if (isNative) {
            var getDeviceInfo = function () { return __awaiter(void 0, void 0, void 0, function () {
                var device;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, Device.getInfo()];
                        case 1:
                            device = _a.sent();
                            if ((device === null || device === void 0 ? void 0 : device.operatingSystem) === 'android' && Number(device === null || device === void 0 ? void 0 : device.osVersion) > 13) {
                                setUseTextarea(true);
                            }
                            return [2 /*return*/];
                    }
                });
            }); };
            void getDeviceInfo();
        }
    }, []);
    return (_jsxs("div", __assign({ className: editorStyles.editorWrapper }, { children: [_jsxs("div", __assign({ className: editorStyles.editor, style: { maxWidth: '100%' }, onClick: function () {
                    ref.current ? ref.current.focus() : textareaRef.current.focus();
                } }, { children: [replyMessage && (_jsxs("div", __assign({ className: editorStyles.replyContainer }, { children: [_jsxs("div", __assign({ className: editorStyles.author }, { children: [_jsxs("div", { children: [t('replyingTo.label'), " ", replyMessage.author.username] }), _jsx("div", __assign({ className: editorStyles.closeIcon, onClick: function () { return setReplyMessage(null); } }, { children: _jsx(FontAwesomeIcon, { icon: ['far', 'xmark-circle'] }) }))] })), _jsxs("p", __assign({ className: editorStyles.content }, { children: __spreadArray([], buildReplyComment(decodeCommentText(contentReplyMessage)), true) }))] }))), useTextArea ? (_jsx("div", __assign({ className: editorStyles.textAreaEditor }, { children: _jsx(TextareaAutosize, { maxRows: 4, ref: textareaRef, placeholder: disabled ? t('noCommentsAllowed.label') : String(placeholder), spellCheck: true, value: editorStateToString(editorState), onChange: function (event) {
                                setEditorState(createEditorStateWithText(event.target.value));
                            } }) }))) : (_jsx(Editor, { editorKey: 'editor', editorState: editorState, onChange: setEditorState, plugins: plugins, ref: ref, spellCheck: true, placeholder: disabled ? t('noCommentsAllowed.label') : String(placeholder) })), _jsx(MentionSuggestions, { open: open, onOpenChange: onOpenChange, suggestions: suggestions, onSearchChange: function (searchValue) {
                            searchValue.value.endsWith(' ') ? setSuggestions([]) : onSearchChange(searchValue);
                        }, onAddMention: function () {
                            setSuggestions([]);
                        }, entryComponent: MentionsEntry })] })), _jsxs("div", __assign({ className: editorStyles.helperText }, { children: [editorState.getCurrentContent().getPlainText('').length, " / 1000 ", t('characters.label')] }))] })));
};
export default MentionsEditorChat;
