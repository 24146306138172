var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var DISPATCH = 'WIS/autoTranslate/DISPATCHED';
export var FULFILL = 'WIS/autoTranslate/FULFILLED';
export var REJECT = 'WIS/autoTranslate/REJECTED';
export var UPDATE_LANGUAGES_DISPATCHED = 'WIS/autoTranslate/UPDATE_LANGUAGES_DISPATCHED';
export var UPDATE_LANGUAGES_FULFILLED = 'WIS/autoTranslate/UPDATE_LANGUAGES_FULFILLED';
export var UPDATE_LANGUAGES_REJECTED = 'WIS/autoTranslate/UPDATE_LANGUAGES_REJECTED';
export var dispatch = function () { return ({ type: DISPATCH }); };
export var fulfill = function (autoTranslate) { return ({
    type: FULFILL,
    autoTranslate: autoTranslate,
}); };
export var reject = function (error) { return ({
    type: REJECT,
    error: error,
}); };
export var updateLanguagesDispatch = function (languages) { return ({
    type: UPDATE_LANGUAGES_DISPATCHED,
    languages: languages,
}); };
export var updateLanguagesFulfill = function (languages) { return ({
    type: UPDATE_LANGUAGES_FULFILLED,
    languages: languages,
}); };
export var updateLanguagesReject = function (error) { return ({
    type: UPDATE_LANGUAGES_REJECTED,
    error: error,
}); };
var initialState = {
    autoTranslate: {},
    isFetching: false,
    isFetched: false,
    isUpdating: false,
    error: null,
};
export default function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case DISPATCH:
            return __assign(__assign({}, state), { isFetching: true });
        case FULFILL:
            return __assign(__assign({}, state), { autoTranslate: action.autoTranslate, isFetching: false, isFetched: true, error: null });
        case REJECT:
            return __assign(__assign({}, state), { error: action.error, isFetching: false });
        case UPDATE_LANGUAGES_DISPATCHED:
            return __assign(__assign({}, state), { isUpdating: true });
        case UPDATE_LANGUAGES_FULFILLED:
            return __assign(__assign({}, state), { isUpdating: false, autoTranslate: __assign(__assign({}, state.autoTranslate), { targetLanguages: action.languages }) });
        case UPDATE_LANGUAGES_REJECTED:
            return __assign(__assign({}, state), { isUpdating: false, error: action.error });
        default:
            return state;
    }
}
