import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import AutoTranslateDisclaimer from '../components/AutoTranslateDisclaimer/AutoTranslateDisclaimer';
import ChatDisclaimer from '../components/ChatDisclaimer/ChatDisclaimer';
import ChatRoomList from '../components/ChatRoomList/ChatRoomList';
import Layout from '../components/Layout/Layout';
import { useEffect } from 'react';
/**
 * Page which displays all of the chats a user can enter
 * @returns {JSX.Element}
 */
var AllChats = function () {
    //set default language to english if not set
    useEffect(function () {
        void FirebaseAnalytics.setCurrentScreen({
            screenName: 'Chat',
            screenClassOverride: 'Chat',
        });
    }, []);
    // const { chatId } = useParams<{ chatId?: string }>();
    return (_jsxs(Layout, { children: [_jsx(ChatRoomList, {}), _jsx(ChatDisclaimer, {}), _jsx(AutoTranslateDisclaimer, {})] }));
};
export default AllChats;
