import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import usePostRemove from '../../hooks/usePostRemove';
import UIListItem from '../UIListItem/UIListItem';
import HideShow from '../HideShow/HideShow';
import ActionButtonGroup from '../ActionButtonGroup/ActionButtonGroup';
import styles from './RemovePostListItem.module.scss';


const RemovePostListItem = ({ postId }) => {
  const {
    removePost,
    removed,
    isRemoving,
    optimisticRemoved,
  } = usePostRemove(postId);
  const { t } = useTranslation();

  const removeStatus = isRemoving ? optimisticRemoved : removed;
  const [showDecision, setShowDecision] = useState(false);
  const [label, setLabel] = useState(t('addSavedList.label'));
  useEffect(() => {
      if (isRemoving) {
          setLabel(t('removingPost.label'));
      } else if (removed) {
          setLabel(t('postRemoved.label'));
      } else {
          setLabel(t('removePost.label'));
  }
  }, [isRemoving, removeStatus]);

  const iconStyles = [
    styles.icon,
    isRemoving && styles.saving,
  ].join(' ');

  const icon = isRemoving
    ? <FontAwesomeIcon className={iconStyles} icon={['far', 'hourglass']} />
    : <FontAwesomeIcon className={iconStyles} icon={['fas', 'trash']} />;

  return (
    <Fragment>
      <UIListItem
        icon={icon}
        disabled={isRemoving}
        onClick={() => setShowDecision(true)}
      >
        {label}
      </UIListItem>
      <HideShow show={showDecision}>
        <div className={styles.decisionCopy}>
          <p>{t('sureRemove.label')}</p>
        </div>
        <ActionButtonGroup>
          <button type="button" onClick={() => setShowDecision(false)}>No</button>
          <button type="button" onClick={removePost}>Yes</button>
        </ActionButtonGroup>
      </HideShow>
    </Fragment>
  );
};

RemovePostListItem.propTypes = {
  postId: PropTypes.string.isRequired,
};

export default RemovePostListItem;
