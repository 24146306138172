export const acceptedImageMimeTypes = [
  'image/png',
  // 'image/gif',
  'image/jpeg',
  'image/jpg',
  // 'image/svg+xml',
];

export const acceptedVideoMimeTypes = ['video/mp4', 'video/x-m4v', 'video/*'];

export const acceptedFileMimeTypes = [
  'application/pdf',
  // 'text/plain',
  // 'application/rtf',
  // 'application/msword',
  // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  // 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  // 'application/vnd.ms-word.document.macroEnabled.12',
  // 'application/vnd.ms-word.template.macroEnabled.12',
  // 'application/vnd.oasis.opendocument.text',
  // 'application/vnd.apple.pages',
  // 'application/x-iwork-pages-sffpages',
];

export const checkFileType = (file, acceptedMimeTypes) =>
  acceptedMimeTypes.some(type => type === file.type);

export const maxUploadSize = 10485760; // 10 MB

export const checkFileSize = file => file.size <= maxUploadSize;

export const minChunkSize = 5242881;

export const chunkFile = file => {
  let startPointer = 0;
  const endPointer = file.size;
  const chunks = [];
  while (startPointer < endPointer) {
    const newStartPointer = startPointer + minChunkSize;
    chunks.push(file.slice(startPointer, newStartPointer));
    startPointer = newStartPointer;
  }
  return chunks;
};
