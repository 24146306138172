var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var DISPATCH = 'WIS/user/profile/DISPATCHED';
export var FULFILL = 'WIS/user/profile/FULFILLED';
export var REJECT = 'WIS/user/profile/REJECTED';
export var UPDATE_INTERESTS_DISPATCHED = 'WIS/user/profile/UPDATE_INTERESTS_DISPATCHED';
export var UPDATE_INTERESTS_FULFILLED = 'WIS/user/profile/UPDATE_INTERESTS_FULFILLED';
export var UPDATE_INTERESTS_REJECTED = 'WIS/user/profile/UPDATE_INTERESTS_REJECTED';
export var dispatch = function () { return ({ type: DISPATCH }); };
export var fulfill = function (profile) { return ({
    type: FULFILL,
    profile: profile,
}); };
export var reject = function (error) { return ({
    type: REJECT,
    error: error,
}); };
export var updateInterestsDispatch = function (interests) { return ({ type: UPDATE_INTERESTS_DISPATCHED, interests: interests }); };
export var updateInterestsFulfill = function (interests) { return ({ type: UPDATE_INTERESTS_FULFILLED, interests: interests }); };
export var updateInterestsReject = function (error) { return ({
    type: UPDATE_INTERESTS_REJECTED,
    error: error,
}); };
var initialState = {
    profile: {},
    isFetching: false,
    isFetched: false,
    isUpdating: false,
    error: null,
};
export default function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case DISPATCH:
            return __assign(__assign({}, state), { isFetching: true });
        case FULFILL:
            return __assign(__assign({}, state), { profile: action.profile, isFetching: false, isFetched: true, error: null });
        case REJECT:
            return __assign(__assign({}, state), { error: action.error, isFetching: false });
        case UPDATE_INTERESTS_DISPATCHED:
            return __assign(__assign({}, state), { isUpdating: true });
        case UPDATE_INTERESTS_FULFILLED:
            return __assign(__assign({}, state), { isUpdating: false, profile: __assign(__assign({}, state.profile), { opportunityInterest: action.interests }) });
        case UPDATE_INTERESTS_REJECTED:
            return __assign(__assign({}, state), { isUpdating: false, error: action.error });
        default:
            return state;
    }
}
