import { combineEpics } from 'redux-observable';
import initialDataLoad from './initialDataLoad';
// import authEpic from './auth';
// import profileEpic from './profile';
import chatEpic from './chat';
// import notificationsEpic from './notifications';
// import boardEpic from './board';
import postEpic from './post';
import postCategoryEpic from './postCategory';
import commentEpic from './comment';
import blockedEpic from './blocked';
import savedEpic from './saved';
import likedEpic from './liked';
// import taxonomyEthnicity from './taxonomyEthnicity';
import taxonomyGender from './taxonomyGender';
import taxonomyCountry from './taxonomyCountry';
import taxonomyMilitaryStatus from './taxonomyMilitaryStatus';
import taxonomyMilitaryService from './taxonomyMilitaryService';
import taxonomyPastGames from './taxonomyPastGames';
import taxonomyPastTrials from './taxonomyPastTrials';
import taxonomyOpportunities from './taxonomyOpportunities';
import taxonomyMedicalStatus from './taxonomyMedicalStatus';
import taxonomyGameInvolvement from './taxonomyGameInvolvement';
import taxonomyFlagReasons from './taxonomyFlagReasons';

const rootEpic = combineEpics(
  initialDataLoad,
  // authEpic,
  // profileEpic,
  // notificationsEpic,
  // boardEpic,
  // chatEpic,
  postEpic,
  postCategoryEpic,
  commentEpic,
  blockedEpic,
  savedEpic,
  likedEpic,
  // taxonomyEthnicity,
  taxonomyGender,
  taxonomyCountry,
  taxonomyMilitaryStatus,
  taxonomyMilitaryService,
  taxonomyPastGames,
  taxonomyPastTrials,
  taxonomyOpportunities,
  taxonomyMedicalStatus,
  taxonomyGameInvolvement,
  taxonomyFlagReasons,
);

export default rootEpic;
