var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var FLAG_DISPATCH = 'WIS/flag/FLAG_DISPATCHED';
export var FLAG_FULFILL = 'WIS/flag/FLAG_FULFILLED';
export var FLAG_REJECT = 'WIS/flag/FLAG_REJECTED';
export var flagDispatch = function (id) { return ({ type: FLAG_DISPATCH, id: id }); };
export var flagFulfill = function () { return ({ type: FLAG_FULFILL }); };
export var flagReject = function (error) { return ({ type: FLAG_REJECT, error: error }); };
var initialState = {
    flagging: false,
    flagged: false,
    error: null,
};
export default function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FLAG_DISPATCH:
            return __assign(__assign({}, state), { flagging: true, flagged: false, error: null });
        case FLAG_FULFILL:
            return __assign(__assign({}, state), { flagging: false, flagged: true, users: action.users, error: null });
        case FLAG_REJECT:
            return __assign(__assign({}, state), { flagging: false, flagged: false, error: action.error });
        default:
            return state;
    }
}
