export var getNotificationRedirect = function (_a) {
    var boardId = _a.boardId, postId = _a.postId, commentId = _a.commentId, messageId = _a.messageId, chatRoomId = _a.chatRoomId;
    var basePath = !boardId ? '/all-posts' : "/boards/".concat(boardId);
    if (postId && commentId) {
        // comment mention
        return "".concat(basePath, "/").concat(postId, "/").concat(commentId, "?redirectFromNotification=1");
    }
    else if (postId) {
        // new post
        return "".concat(basePath, "/").concat(postId, "?redirectFromNotification=1");
    }
    else if (messageId && chatRoomId) {
        return "chat/".concat(chatRoomId, "/").concat(messageId);
        // return `/chat-rooms/${chatRoomId}/messages/?around=${messageId}`;
    }
};
