var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var COMMENT_GET_BY_POST_ID_DISPATCH = 'WIS/comments/COMMENT_GET_BY_POST_ID_DISPATCHED';
export var COMMENT_GET_BY_POST_ID_FULFILL = 'WIS/comments/COMMENT_GET_BY_POST_ID_FULFILLED';
export var COMMENT_GET_BY_POST_ID_REJECT = 'WIS/comments/COMMENT_GET_BY_POST_ID_REJECTED';
export var COMMENT_CREATE_DISPATCH = 'WIS/comments/COMMENT_CREATE_DISPATCHED';
export var COMMENT_CREATE_FULFILL = 'WIS/comments/COMMENT_CREATE_FULFILLED';
export var COMMENT_CREATE_REJECT = 'WIS/comments/COMMENT_CREATE_REJECTED';
export var COMMENT_UNPUBLISH_DISPATCH = 'WIS/posts/COMMENT_UNPUBLISH_DISPATCHED';
export var COMMENT_UNPUBLISH_FULFILL = 'WIS/posts/COMMENT_UNPUBLISH_FULFILLED';
export var COMMENT_UNPUBLISH_REJECT = 'WIS/posts/COMMENT_UNPUBLISH_REJECTED';
export var commentGetByPostIdDispatch = function (postId) { return ({
    type: COMMENT_GET_BY_POST_ID_DISPATCH,
    postId: postId,
}); };
export var commentGetByPostIdFulfill = function (postId, comments) { return ({ type: COMMENT_GET_BY_POST_ID_FULFILL, postId: postId, comments: comments }); };
export var commentGetByPostIdReject = function (error) { return ({
    type: COMMENT_GET_BY_POST_ID_REJECT,
    error: error,
}); };
export var commentCreateDispatch = function (postId) { return ({
    type: COMMENT_CREATE_DISPATCH,
    postId: postId,
}); };
export var commentCreateFulfill = function (postId, comment) { return ({
    type: COMMENT_CREATE_FULFILL,
    postId: postId,
    comment: comment,
}); };
export var commentCreateReject = function (error) { return ({
    type: COMMENT_CREATE_REJECT,
    error: error,
}); };
export var commentUnpublishDispatch = function (postId, commentId) { return ({ type: COMMENT_UNPUBLISH_DISPATCH, postId: postId, commentId: commentId }); };
export var commentUnpublishFulfill = function (comment) { return ({
    type: COMMENT_UNPUBLISH_FULFILL,
    comment: comment,
}); };
export var commentUnpublishReject = function (postId, error) { return ({
    type: COMMENT_UNPUBLISH_REJECT,
    postId: postId,
    error: error,
}); };
var initialState = {
    commentsByPostId: {},
    isFetching: false,
    isFetched: false,
    isPosting: false,
    isPosted: false,
    error: null,
};
var removeCommentFromComments = function (commentId, comments) {
    var newCommentsState = __spreadArray([], comments, true);
    var commentIndex = newCommentsState.findIndex(function (comment) { return comment.id === commentId; });
    newCommentsState.splice(commentIndex, 1);
    return newCommentsState;
};
export default function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case COMMENT_CREATE_DISPATCH:
            return __assign(__assign({}, state), { isPosting: true, isPosted: false, error: null });
        case COMMENT_CREATE_FULFILL:
            return __assign(__assign({}, state), { isPosting: false, isPosted: true, error: null });
        case COMMENT_CREATE_REJECT:
            return __assign(__assign({}, state), { isPosting: false, isPosted: false, error: action.error });
        case COMMENT_GET_BY_POST_ID_DISPATCH: {
            var currentComments = state.commentsByPostId[action.postId]
                ? state.commentsByPostId[action.postId].comments
                : [];
            var commentsForPost = {};
            commentsForPost[action.postId] = {
                comments: __spreadArray([], (currentComments || []), true),
                isFetched: false,
                isFetching: true,
            };
            return __assign(__assign({}, state), { commentsByPostId: __assign(__assign({}, state.commentsByPostId), commentsForPost) });
        }
        case COMMENT_GET_BY_POST_ID_FULFILL: {
            var commentsForPost = {};
            /*
              ensure correct by filtering comments by postId
              then rename key 'post' to 'postId'
            */
            var sanitisedComments = action.comments
                .filter(function (comment) { return comment.post === action.postId; })
                .map(function (_a) {
                var post = _a.post, rest = __rest(_a, ["post"]);
                return (__assign(__assign({}, rest), { postId: post }));
            });
            commentsForPost[action.postId] = {
                comments: sanitisedComments,
                isFetched: true,
                isFetching: false,
                lastFetched: Date.now(),
                unpublishedSinceLastFetched: [],
                error: null,
            };
            return __assign(__assign({}, state), { commentsByPostId: __assign(__assign({}, state.commentsByPostId), commentsForPost) });
        }
        case COMMENT_GET_BY_POST_ID_REJECT: {
            var commentsForPost = {};
            commentsForPost[action.postId] = {
                isFetched: false,
                isFetching: false,
                error: action.error,
            };
            return __assign(__assign({}, state), { commentsByPostId: __assign(__assign({}, state.commentsByPostId), commentsForPost) });
        }
        case COMMENT_UNPUBLISH_DISPATCH: {
            var currentComments = state.commentsByPostId[action.postId]
                ? state.commentsByPostId[action.postId].comments
                : [];
            var commentsForPost = {};
            commentsForPost[action.postId] = {
                comments: __spreadArray([], currentComments, true),
                isUnpublishing: true,
                unPublishingId: action.commentId,
            };
            return __assign(__assign({}, state), { commentsByPostId: __assign(__assign({}, state.commentsByPostId), commentsForPost), error: null });
        }
        case COMMENT_UNPUBLISH_FULFILL: {
            var commentsForPost = {};
            commentsForPost[action.comment.post] = {
                comments: removeCommentFromComments(action.comment.id, state.commentsByPostId[action.comment.post].comments),
                unpublishedSinceLastFetched: __spreadArray(__spreadArray([], (state.commentsByPostId[action.comment.post]
                    .unpublishedSinceLastFetched || []), true), [
                    action.comment.id,
                ], false),
                isUnpublishing: false,
                unPublishingId: null,
                unPublishError: null,
            };
            return __assign(__assign({}, state), { commentsByPostId: __assign(__assign({}, state.commentsByPostId), commentsForPost) });
        }
        case COMMENT_UNPUBLISH_REJECT: {
            var commentsForPost = {};
            commentsForPost[action.postId] = {
                isUnpublishing: false,
                unPublishingId: null,
                unPublishError: action.error,
            };
            return __assign(__assign({}, state), { commentsByPostId: __assign(__assign({}, state.commentsByPostId), commentsForPost) });
        }
        default:
            return state;
    }
}
