var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useEffect } from 'react';
import { useProfile } from '../util/APIDjango';
import api from '../util/api';
export var useProfileData = function () {
    var _a = useProfile(), profileData = _a[0], setProfileData = _a[1];
    useEffect(function () {
        var _a;
        if (!((_a = profileData === null || profileData === void 0 ? void 0 : profileData.profile) === null || _a === void 0 ? void 0 : _a.id)) {
            setProfileData(__assign(__assign({}, profileData), { isFetching: true }));
            void api
                .profileGet()
                .then(function (data) {
                setProfileData(__assign(__assign({}, data), { profile: data, isFetching: false, isFetched: true, error: null }));
            })
                .catch(function (error) {
                setProfileData(__assign(__assign({}, profileData), { error: error, isFetching: false }));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return {
        profileData: profileData,
        setProfileData: setProfileData,
    };
};
