import * as yup from 'yup';

// import {
//   updateInterestsDispatch,
//   updateInterestsFulfill,
//   updateInterestsReject,
// } from '../../redux/modules/profile';
// import { useDispatch, useSelector } from 'react-redux';

import Button from '../../components/Button/Button';
import ButtonGroup from '../../components/ButtonGroup/ButtonGroup';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { Formik } from 'formik';
import Loader from '../../components/Loader/Loader';
import OpportunityInterestField from '../fields/OpportunityInterestField';
import React, { useEffect } from 'react';
import api from '../../util/api';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { useProfile } from '../../util/APIDjango';
import { useProfileData } from '../../hooks/useProfileData';

export const InterestsForm = ({ innerRef, label = '', callBack = () => {} }) => {
  const { t } = useTranslation();
  // const dispatch = useDispatch();
  // const { profileFetching, profileFetched, profileError, opportunityInterest } =
  //   useSelector(({ profile }) => ({
  //     profileFetching: profile.isFetching,
  //     profileFetched: profile.isFetched,
  //     profileError: profile.error,
  //     opportunityInterest:
  //       profile?.profile?.opportunityInterest?.map(item => item.id) || [],
  //   }));

  const { profileData, setProfileData } = useProfileData();
  const profileFetching = profileData?.isFetching;
  const profileFetched = profileData?.isFetched;
  const profileError = profileData?.error;
  const opportunityInterest = profileData?.profile?.opportunityInterest?.map(item => item.id) || [];

  const arraysEqual = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
      return false;
    }

    const arrCopy1 = [...arr1].sort();
    const arrCopy2 = [...arr2].sort();

    for (let i = arrCopy1.length; i--; ) {
      if (arrCopy1[i] !== arrCopy2[i]) {
        return false;
      }
    }

    return true;
  };

  const updateInterestsDispatch = () => setProfileData({ ...profileData, isUpdating: true });
  const updateInterestsFulfill = opportunityInterest =>
    setProfileData({
      ...profileData,
      isUpdating: false,
      profile: {
        ...profileData.profile,
        opportunityInterest: opportunityInterest,
      },
    });
  const updateInterestsReject = errorMessage =>
    setProfileData({
      ...profileData,
      isUpdating: false,
      error: errorMessage,
    });

  const handleInterestsUpdate = (values, { setSubmitting, setStatus, status }) => {
    setSubmitting(true);
    // dispatch(updateInterestsDispatch(values?.opportunityInterest));
    updateInterestsDispatch();
    api
      .updateInterests(values?.opportunityInterest)
      .then(response => {
        setSubmitting(false);
        setStatus({
          ...status,
          formSuccessSubmitCount: status?.formSuccessSubmitCount
            ? status?.formSuccessSubmitCount + 1
            : 1,
        });
        // dispatch(updateInterestsFulfill(response.opportunityInterest));
        updateInterestsFulfill(response?.opportunityInterest);
        callBack && callBack();
      })
      .catch(error => {
        setSubmitting(false);
        setStatus({ ...status, formError: error.message });
        // dispatch(updateInterestsReject(error.message));
        updateInterestsReject(error.message);
      });
  };

  if (profileFetching) {
    return <Loader />;
  }

  if (profileFetched) {
    return (
      <Formik
        ref={innerRef}
        validationSchema={yup.object().shape({
          opportunityInterest: yup.array().of(yup.number()).required(t('required.label')),
        })}
        initialValues={{
          opportunityInterest,
        }}
        onSubmit={handleInterestsUpdate}>
        {({ status, handleSubmit, isSubmitting, values }) => {
          const disabled = arraysEqual(values?.opportunityInterest, opportunityInterest);
          const submitLabel = () => {
            if (
              !status?.formSuccessSubmitCount ||
              status?.formSuccessSubmitCount < 1 ||
              !disabled
            ) {
              return t('save.label');
            }
            return t('saved.label');
          };
          return (
            <form onSubmit={handleSubmit}>
              <OpportunityInterestField
                name="opportunityInterest"
                label={t('opportunityInterest.label')}
              />
              {status && status.formError && <ErrorMessage message={status.formError} />}
              {label ? (
                <ButtonGroup borderTop>
                  <Button
                    gutterTop
                    type="submit"
                    label={label || submitLabel()}
                    disabled={disabled || isSubmitting}
                    fullWidth
                  />
                </ButtonGroup>
              ) : (
                <Button
                  gutterTop
                  type="submit"
                  label={label || submitLabel()}
                  disabled={disabled || isSubmitting}
                  fullWidth
                />
              )}
            </form>
          );
        }}
      </Formik>
    );
  }
  return (
    <p>
      {t('thereWasAnErrorFetchingYourProfile.label')}: &nbsp;
      {profileError || 'unknown error'}
    </p>
  );
};

export default withRouter(InterestsForm);
