import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { TransitionMotion, spring } from 'react-motion';
import useModal from '../../hooks/useModal';
import styles from './Modal.module.scss';

const Modal = ({ show, backDropClick, children }) => {
  const setShow = useModal();
  useEffect(() => {
    setShow(show);
  }, [show]);
  const modalRoot = document.getElementById('modal-root');
  const willLeave = () => ({ opacity: spring(0), y: spring(-200) });
  const willEnter = () => ({ opacity: 0, y: -400 });

  return ReactDOM.createPortal(
    <TransitionMotion
      willLeave={willLeave}
      willEnter={willEnter}
      styles={
        show
          ? [
              {
                key: 'a',
                style: {
                  opacity: spring(1),
                  y: spring(0),
                },
              },
            ]
          : []
      }>
      {items => {
        if (items.length) {
          const { key, style } = items[0];
          return (
            <div
              key={key}
              className={styles.backdrop}
              style={{ opacity: style.opacity }}
              onClick={backDropClick}>
              <div
                className={styles.modal}
                style={{
                  position: 'relative',
                  bottom: style.y,
                  opacity: style.opacity,
                }}>
                {children}
              </div>
            </div>
          );
        }
        return null;
      }}
    </TransitionMotion>,
    modalRoot,
  );
};

Modal.propTypes = {
  show: PropTypes.bool,
  backDropClick: PropTypes.func,
};

Modal.defaultProps = {
  show: false,
  backDropClick: null,
};

export default Modal;
