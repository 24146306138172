import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Img.module.scss';


const Img = ({
  className,
  src,
  alt,
  ratio,
}) => {
  const [loaded, setLoaded] = useState(false);
  const calcHeight = (ratio?.height / ratio?.width) * 100;
  const placeholderStyle = calcHeight ? {
    paddingTop: `${calcHeight}%`,
  } : null;
  const placeholderClassNames = [
    styles.placeholder,
    loaded && styles.loaded,
    className,
  ].join(' ');

  return (
    <div
      className={placeholderClassNames}
      // style={placeholderStyle}
    >
      <img
        className={[loaded && styles.loaded]}
        // style={ratio && { position: 'absolute' }}
        src={src}
        alt={alt}
        onLoad={() => setLoaded(true)}
      />
    </div>
  );
};

Img.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  ratio: PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }),
};

Img.defaultProps = {
  ratio: null,
};

export default Img;
