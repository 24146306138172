/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable prefer-promise-reject-errors */

import { ajax } from 'rxjs/ajax';
import { any } from 'prop-types';
import jwtDecode from 'jwt-decode';
import { minChunkSize } from './files';
import qs from 'qs';
import { createGlobalState } from 'react-hooks-global-state';

const initialGlobalState = {
  notificationsState: {
    notifications: {
      count: 0,
      results: [],
    },
    pageNumber: 0,
    pageSize: 10,
  },
  authState: {
    uid: null,
    user: {},
    authenticating: false,
    reAuthenticating: false,
    authenticated: false,
    unauthenticating: false,
    error: null,
    fbcmError: null,
    redirectToReferrer: false,
  },
  taxonomyCountryState: {
    terms: [],
    isFetching: false,
    isFetched: true,
    error: null,
  },
  taxonomyGenderState: {
    terms: [],
    isFetching: false,
    isFetched: true,
    error: null,
  },
  taxonomyEthnicityState: {
    terms: [],
    isFetching: false,
    isFetched: true,
    error: null,
  },
  taxonomyMilitaryServiceState: {
    terms: [],
    isFetching: false,
    isFetched: true,
    error: null,
  },
  taxonomyMilitaryStatusState: {
    terms: [],
    isFetching: false,
    isFetched: true,
    error: null,
  },
  taxonomyPastGamesState: {
    terms: [],
    isFetching: false,
    isFetched: true,
    error: null,
  },
  taxonomyPastTrialsState: {
    terms: [],
    isFetching: false,
    isFetched: true,
    error: null,
  },
  taxonomyOpportunitiesState: {
    terms: [],
    isFetching: false,
    isFetched: true,
    error: null,
  },
  taxonomyMedicalStatusState: {
    terms: [],
    isFetching: false,
    isFetched: true,
    error: null,
  },
  taxonomyGameInvolvementState: {
    terms: [],
    isFetching: false,
    isFetched: true,
    error: null,
  },
  taxonomyMilitaryFlagReasonsState: {
    terms: [],
    isFetching: false,
    isFetched: true,
    error: null,
  },
  signUpState: {
    error: null,
    submitted: false,
    submitting: false,
  },
  postState: {
    posts: [],
    isFetching: false,
    isFetched: false,
    lastFetched: null,
    isPosting: false,
    isPosted: false,
    unpublishedSinceLastFetched: [],
    isUnpublishing: false,
    unPublishingId: null,
    unPublishError: null,
    fetchError: null,
    createError: null,
    error: null,
  },
  postCategoriesState: {
    categories: [],
    isFetching: false,
    isFetched: false,
    fetchError: null,
  },
  likedState: {
    posts: ['P000002', 'P000004', 'P000006'],
    updating: false,
    error: null,
  },
  profileState: {
    error: null,
    isFetched: false,
    isFetching: false,
    isUpdating: false,
    profile: {
      id: null,
      medicalStatusWis: null,
    },
    blockedUsersUpdating: false,
    blockedUsers: [],
    opportunityInterest: [],
    notificationMethod: null,
    autoTranslatePreference: true,
  },
  notiPreferenceState: {
    error: null,
    isFetched: false,
    isFetching: false,
    notificationMethod: null,
  },
  chatRoomState: {
    fetchError: null,
    isFetching: false,
    isFetched: false,
    rooms: [],
  },
  messagesState: {
    fetchError: null,
    isFetched: false,
    isFetching: false,
    lastMessageIdFetched: null,
    messagesByRoom: {
      likedByMe: [],
      next: null,
      previous: null,
      results: [],
    },
    messagesFetched: false,
    messagesFetching: false,
  },
  boardsState: {
    boards: [],
    isFetching: false,
    isFetched: false,
    fetchError: null,
  },
  commentsState: {
    commentsByPostId: {},
    isFetching: false,
    isFetched: false,
    isPosting: false,
    isPosted: false,
    error: null,
  },
  flagState: {
    flagging: false,
    flagged: false,
    error: null,
  },
  useFiles: {
    groups: {},
  },
};

const { setGlobalState, useGlobalState } = createGlobalState(initialGlobalState);

export const useNotifications = () => useGlobalState('notificationsState');
export const useAuth = () => useGlobalState('authState');
export const useSignUp = () => useGlobalState('signUpState');
export const useTaxonomyCountry = () => useGlobalState('taxonomyCountryState');
export const useTaxonomyGender = () => useGlobalState('taxonomyGenderState');
export const useTaxonomyEthnicity = () => useGlobalState('taxonomyEthnicityState');
export const useTaxonomyMilitaryService = () => useGlobalState('taxonomyMilitaryServiceState');
export const useTaxonomyMilitaryStatus = () => useGlobalState('taxonomyMilitaryStatusState');
export const useTaxonomyPastGames = () => useGlobalState('taxonomyPastGamesState');
export const useTaxonomyPastTrials = () => useGlobalState('taxonomyPastTrialsState');
export const useTaxonomyMedicalStatus = () => useGlobalState('taxonomyMedicalStatusState');
export const useTaxonomyOpportunities = () => useGlobalState('taxonomyOpportunitiesState');
export const useTaxonomyGameInvolvement = () => useGlobalState('taxonomyGameInvolvementState');
export const useTaxonomyFlagReasons = () => useGlobalState('taxonomyMilitaryFlagReasonsState');
export const usePosts = () => useGlobalState('postState');
export const usePostCategories = () => useGlobalState('postCategoriesState');
export const useProfile = () => useGlobalState('profileState');
export const useNotiPreference = () => useGlobalState('notiPreferenceState');
export const useChatRooms = () => useGlobalState('chatRoomState');
export const useMessages = () => useGlobalState('messagesState');
export const useLiked = () => useGlobalState('likedState');
export const useBoards = () => useGlobalState('boardsState');
export const useComments = () => useGlobalState('commentsState');
export const useFlags = () => useGlobalState('flagState');
export const useFiles = () => useGlobalState('useFiles');

const saveToken = (name, token) => Promise.resolve().then(() => localStorage.setItem(name, token));

const loadToken = name => localStorage.getItem(name);

const removeToken = name => Promise.resolve().then(() => localStorage.removeItem(name));

// eslint-disable-next-line no-unused-vars
const checkForToken = name =>
  new Promise((res, rej) => {
    const token = loadToken(name);
    try {
      if (token) {
        res(token);
      } else {
        throw Error('no token found');
      }
    } catch (err) {
      rej(err);
    }
  });
export default class APIDjango {
  constructor({ domain, locale }) {
    this.domain = domain;
    this.locale = locale;
    this.accessToken = loadToken('accessToken');
    this.refreshToken = loadToken('refreshToken');
    this.getToken = this.getToken.bind(this);
    this.unsafeGetToken = this.unsafeGetToken.bind(this);
    this.signIn = this.signIn.bind(this);
    this.sendRefreshRequest = this.sendRefreshRequest.bind(this);
    this.createAjaxPromise = this.createAjaxPromise.bind(this);

    this.resumeSession = this.resumeSession.bind(this);
    this.signOut = this.signOut.bind(this);
    this.signUp = this.signUp.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.uploadVideoToAPI = this.uploadVideoToAPI.bind(this);
    this.verifyVideoUpload = this.verifyVideoUpload.bind(this);
    this.videoAbortUpload = this.videoAbortUpload.bind(this);
    this.uploadRequiredDocuments = this.uploadRequiredDocuments.bind(this);
    this.profileGet = this.profileGet.bind(this);
    this.likePost = this.likePost.bind(this);
    this.updateInterests = this.updateInterests.bind(this);
    this.getMentionableUsersForBoard = this.getMentionableUsersForBoard.bind(this);
    this.notificationsGetHistory = this.notificationsGetHistory.bind(this);
    // this.notificationsGetRead = this.notificationsGetRead.bind(this);
    this.notificationsUpdateRead = this.notificationsUpdateRead.bind(this);
    this.notificationsGetPreferences = this.notificationsGetPreferences.bind(this);
    this.notificationsUpdatePreferences = this.notificationsUpdatePreferences.bind(this);
    this.blockUser = this.blockUser.bind(this);
    this.unblockUser = this.unblockUser.bind(this);
    this.updateAvatar = this.updateAvatar.bind(this);
    this.updateFirebaseToken = this.updateFirebaseToken.bind(this);
    this.getPosts = this.getPosts.bind(this);
    this.getPostsIncludingGlobal = this.getPostsIncludingGlobal.bind(this);
    this.uploadVideoChunk = this.uploadVideoChunk.bind(this);
    this.getChunkUploadArray = this.getChunkUploadArray.bind(this);
    this.createPost = this.createPost.bind(this);
    this.unpublishPost = this.unpublishPost.bind(this);
    this.commentsForPost = this.commentsForPost.bind(this);
    this.createComment = this.createComment.bind(this);
    this.flagContent = this.flagContent.bind(this);
    this.getTaxonomy = this.getTaxonomy.bind(this);
    this.getTaxonomyNew = this.getTaxonomyNew.bind(this);
    this.taxonomyEthnicity = this.taxonomyEthnicity.bind(this);
    this.taxonomyGender = this.taxonomyGender.bind(this);
    this.taxonomyCountry = this.taxonomyCountry.bind(this);
    this.getCountryList = this.getCountryList.bind(this);
    this.getMilitaryServices = this.getMilitaryServices.bind(this);
    this.taxonomyMilitaryStatus = this.taxonomyMilitaryStatus.bind(this);
    this.taxonomyFlagReasons = this.taxonomyFlagReasons.bind(this);
    this.taxonomyMilitaryService = this.taxonomyMilitaryService.bind(this);
    this.taxonomyPastGames = this.taxonomyPastGames.bind(this);
    this.taxonomyPastTrials = this.taxonomyPastTrials.bind(this);
    this.taxonomyOpportunities = this.taxonomyOpportunities.bind(this);
    this.taxonomyMedicalStatus = this.taxonomyMedicalStatus.bind(this);
    this.taxonomyGameInvolvement = this.taxonomyGameInvolvement.bind(this);
    this.translate = this.translate.bind(this);
    this.createChatMessage = this.createChatMessage.bind(this);
    this.chatMessageById = this.chatMessageById.bind(this);
    this.chatMessages = this.chatMessages.bind(this);
    this.chatRooms = this.chatRooms.bind(this);
    this.chatRoomById = this.chatRoomById.bind(this);
    this.flagChatMessage = this.flagChatMessage.bind(this);
    this.getHiddenChatMessage = this.getHiddenChatMessage.bind(this);
    this.hideChatMessage = this.hideChatMessage.bind(this);
    this.deleteChatMessage = this.deleteChatMessage.bind(this);
    this.likeChatMessage = this.likeChatMessage.bind(this);
    this.removeLikeChatMessage = this.removeLikeChatMessage.bind(this);
    this.getFlaggedMessage = this.getFlaggedMessage.bind(this);
    this.getPostCategories = this.getPostCategories.bind(this);
    this.getPostCategoriesNew = this.getPostCategoriesNew.bind(this);
    this.userParticipated = this.userParticipated.bind(this);
    this.updateProfileV2 = this.updateProfileV2.bind(this);
    this.getCountryOfResidence = this.getCountryOfResidence.bind(this);
    this.checkEmail = this.checkEmail.bind(this);
    this.checkUsername = this.checkUsername.bind(this);
  }

  createAjaxPromise = ({ headers, ...customAjaxConfig }) => {
    const ajaxConfig = {
      withCredentials: true,
      crossDomain: true,
      responseType: 'json',
      headers: { 'Content-Type': 'application/json' },
    };

    return new Promise((resolve, reject) =>
      ajax({
        ...ajaxConfig,
        ...customAjaxConfig,
        headers: { ...ajaxConfig.headers, ...headers },
      })
        .toPromise()
        .then(response => resolve(response.response))
        .catch(err => reject(err)),
    );
  };

  getTaxonomy = endPoint =>
    this.createAjaxPromise({
      method: 'GET',
      url: `${this.domain}/taxonomy/${endPoint}/`,
    });

  getCountryOfResidence = () =>
    this.createAjaxPromise({
      method: 'GET',
      url: `${this.domain}/user/v2/country_of_residence`,
    });

  getCountryList = () =>
    this.createAjaxPromise({
      method: 'GET',
      url: `${this.domain}/user/v2/country_of_service`,
    });

  getMilitaryServices = countryCode =>
    this.createAjaxPromise({
      method: 'GET',
      url: `${this.domain}/user/v2/military_unit?id=${countryCode}`,
    });

  getTaxonomyNew = endPoint => {
    return new Promise((resolve, reject) => {
      this.createAjaxPromise({
        method: 'GET',
        url: `${this.domain}/taxonomy/${endPoint}/`,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  signIn(email, password) {
    if (!email) {
      return Promise.reject(Error('No email!'));
    }

    if (!password) {
      return Promise.reject(Error('No password!'));
    }

    // If token is already being fetched, wait for that request.
    if (this.authPromise) {
      return this.authPromise;
    }

    this.authPromise = this.createAjaxPromise({
      method: 'POST',
      url: `${this.domain}/token/`,
      body: { email, password },
    })
      .then(({ access, refresh }) => {
        // store tokens
        this.accessToken = access;
        saveToken('accessToken', access);
        this.refreshToken = refresh;
        saveToken('refreshToken', refresh);
        const decodedAccessToken = jwtDecode(access);
        delete this.authPromise;
        return decodedAccessToken.user_id;
      })
      .catch(res => {
        delete this.authPromise;
        if (res && res.response && res.response.detail) {
          return Promise.reject(Error(res.response.detail));
        }
        return Promise.reject(Error('There was an error signing in'));
      });
    return this.authPromise;
  }

  sendRefreshRequest() {
    return this.createAjaxPromise({
      method: 'POST',
      url: `${this.domain}/token/refresh/`,
      body: { refresh: this.refreshToken },
    })
      .then(({ access }) => {
        // store tokens
        console.log('token referesh', access);
        this.accessToken = access;
        saveToken('accessToken', access);
        delete this.authPromise;
        return this.accessToken;
      })
      .catch(() => {
        // Failure to fetch token is most likely to be incorrect
        // credentials, but it could be something else like the
        // internet connection being dropped. Triggering logout
        // is extreme but should be secure in all cases.
        // TODO logout work here
      });
  }

  getToken() {
    return this.unsafeGetToken().catch(() =>
      Promise.reject(Error('Authentication token expired, please re-authenticate')),
    );
  }

  unsafeGetToken() {
    // If token is already being fetched, wait for that request.
    if (this.authPromise) {
      console.log('authpromise');
      return this.authPromise;
    }

    // Check if we've already got an access token.
    if (this.accessToken) {
      const timeWindow = 15;
      const now = Math.floor(Date.now() / 1000);
      // If the access token's not expired, use it!
      const decodedAccessToken = jwtDecode(this.accessToken);
      const decodedRefreshToken = jwtDecode(this.refreshToken);

      if (decodedAccessToken.exp - timeWindow > now) {
        return Promise.resolve(this.accessToken);
      }

      // If access token has expired & we have a valid refresh token, refresh it.
      if (decodedRefreshToken.exp - timeWindow > now) {
        this.authPromise = this.sendRefreshRequest();
        return this.authPromise;
      }
    }
    // If the refresh token is also expired, the user needs to re-authenticate.
    return Promise.reject(Error('Tokens expired, please re-authenticate.'));
  }

  resumeSession = () =>
    this.getToken()
      .then(accessToken => {
        const decodedAccessToken = jwtDecode(accessToken);
        return decodedAccessToken.user_id;
      })
      .catch(err => {
        throw err;
      });

  // TODO temporary solution until endpoint exists
  signOut = () =>
    new Promise((res, rej) => {
      removeToken('accessToken')
        .then(() => removeToken('refreshToken'))
        .then(() => {
          Object.keys(initialGlobalState).forEach(stateKey => {
            if (stateKey.indexOf('taxonomy') === -1) {
              setGlobalState(stateKey, initialGlobalState[stateKey]);
            }
          });
          res();
        })
        .catch(err => rej(err));
    });

  signUp = values =>
    this.createAjaxPromise({
      method: 'POST',
      url: `${this.domain}/user/v2/create/`,
      body: values,
    }).catch(res => {
      if (res && res.response && res.response.detail) {
        return Promise.reject(Error(res.response.detail));
      }
      return Promise.reject(Error('There was an error signing up'));
    });

  userParticipated = values =>
    this.createAjaxPromise({
      method: 'POST',
      url: `${this.domain}/user/v2/user_participated/`,
      body: values,
    }).catch(res => {
      if (res && res.response && res.response.detail) {
        return Promise.reject(Error(res.response.detail));
      }
      return Promise.reject(Error('There was an error signing up'));
    });

  uploadFile = ({ image }) => {
    const uploadFilePromise = accessToken =>
      new Promise((res, rej) => {
        const xhr = new XMLHttpRequest();
        xhr.upload.onprogress = function (e) {
          const percentComplete = (e.loaded / e.total) * 100;
          console.log('%%%%%%%%%%%%%%%%%%%%');
          console.log(`progress: ${percentComplete}%`);
          console.log('%%%%%%%%%%%%%%%%%%%%');
        };

        xhr.onload = function () {
          if (this.status >= 200 && this.status < 300) {
            res(JSON.parse(xhr.response));
          } else {
            rej({
              status: xhr.status,
              statusText: xhr.statusText,
            });
          }
        };

        xhr.onerror = function () {
          rej({
            status: this.status,
            statusText: xhr.statusText,
          });
        };

        xhr.open('POST', `${this.domain}/image/upload/`);
        xhr.setRequestHeader('Authorization', `Bearer ${accessToken}`);
        xhr.setRequestHeader('Content-Type', 'application/octet-stream');
        xhr.setRequestHeader('Accept', 'application/json');
        xhr.setRequestHeader(
          'Content-Disposition',
          `form-data; name="file"; filename="${image.name}"`,
        );
        xhr.send(image);
      });

    return this.getToken().then(accessToken => uploadFilePromise(accessToken));
  };

  // Initial Upload to API
  uploadVideoToAPI = video => {
    const getIdPromise = accessToken =>
      new Promise((res, rej) => {
        // Request taken from github django docs
        const numberOfChunks = Math.ceil(video.size / minChunkSize);
        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${accessToken}`);
        myHeaders.append('Content-Type', 'application/json');
        const raw = JSON.stringify({
          filename: video.name,
          parts: numberOfChunks,
        });

        const requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow',
        };

        fetch(`${this.domain}/video/upload/`, requestOptions)
          .then(response =>
            // if accepted then return the response else reject
            response.status >= 200 && response.status < 300 ? response.json() : rej(response),
          )
          // Resolve the promise with either the result or an error
          .then(result => {
            console.log('/video/upload', result);
            return res(result);
          })
          .catch(error => rej(error));
      });

    return this.getToken().then(accessToken => getIdPromise(accessToken));
  };

  uploadVideoChunk = (chunk, url, videoType) =>
    fetch(url, {
      headers: { 'Content-Type': videoType },
      body: chunk,
      method: 'PUT',
    })
      .then(data => data.headers.get('etag'))
      .catch(e => {
        console.error(e.message);
        return undefined;
      });

  getChunkUploadArray = ({ chunks, videoType, partUploadUrls }) =>
    partUploadUrls.map(({ partNumber, url }) =>
      this.uploadVideoChunk(chunks[partNumber - 1], url, videoType),
    );

  verifyVideoUpload = ({ videoEtags, uuid }) => {
    const verifyUploadPromise = accessToken =>
      new Promise((res, rej) => {
        // Request taken from github django docs
        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${accessToken}`);
        myHeaders.append('Content-Type', 'application/json');

        const raw = JSON.stringify({
          etags: videoEtags,
        });

        const requestOptions = {
          method: 'PUT',
          headers: myHeaders,
          body: raw,
          redirect: 'follow',
        };

        fetch(`${this.domain}/video/upload/${uuid}/`, requestOptions)
          .then(response =>
            // if accepted then return the response else reject
            response.status >= 200 && response.status < 300 ? response.text() : rej(response),
          )
          // Resolve the promise with either the result or an error
          .then(result => res(JSON.parse(result)))
          .catch(error => rej(error));
      });

    return this.getToken().then(accessToken => verifyUploadPromise(accessToken));
  };

  videoAbortUpload = ({ uuid }) => {
    const verifyUploadPromise = accessToken =>
      new Promise((res, rej) => {
        // Request taken from github django docs
        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${accessToken}`);
        myHeaders.append('Content-Type', 'application/json');

        const requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };

        fetch(`${this.domain}/video/abort/${uuid}`, requestOptions)
          .then(response =>
            response.status >= 200 && response.status < 300 ? response.text() : rej(response),
          )
          // Resolve the promise with either the result or an error
          .then(result => res(JSON.parse(result)))
          .catch(error => {
            console.log('error from video abort', error);
            rej(error);
          });
      });

    return this.getToken().then(accessToken => verifyUploadPromise(accessToken));
  };

  uploadRequiredDocuments = (file, userRef, percProgress, legacy) =>
    new Promise((res, rej) => {
      try {
        const xhr = new XMLHttpRequest();

        if (percProgress) {
          xhr.upload.onprogress = function (e) {
            const percentComplete = (e.loaded / e.total) * 100;
            percProgress(percentComplete);
          };
        }

        xhr.onload = function () {
          if (this.status >= 200 && this.status < 300) {
            res(JSON.parse(xhr.response));
          } else {
            try {
              if (xhr.status === 415) {
                throw Error('fileRejected.label');
              }
              const response = JSON.parse(xhr.responseText);
              rej(Error(response.detail));
            } catch (error) {
              rej(Error(error.message || 'somethingWentWrongWithUpload.label'));
            }
          }
        };

        xhr.onerror = function () {
          rej({
            status: xhr.status,
            statusText: xhr.statusText,
          });
        };
        const url = legacy
          ? `requested-documents/upload/${userRef}/`
          : 'requested-documents/v2/upload/';
        xhr.open('POST', `${this.domain}/${url}`);

        xhr.setRequestHeader(
          'Content-Disposition',
          `form-data; name="file"; filename="${file.name}"`,
        );
        if (legacy) {
          xhr.setRequestHeader('Content-Type', 'application/octet-stream');
          xhr.setRequestHeader('Accept', 'application/json');
          xhr.send(file);
        } else {
          const formData = new FormData();
          formData.append('file', file);
          formData.append('email', userRef);

          xhr.send(formData);
        }
      } catch (error) {
        rej(error);
      }
    });

  forgotPassword = ({ email }) =>
    this.createAjaxPromise({
      method: 'POST',
      url: `${this.domain}/user/reset-password/`,
      body: { email },
    });

  resetPassword = ({ oldPassword, newPassword, confirmNewPassword }) =>
    this.getToken().then(accessToken =>
      this.createAjaxPromise({
        method: 'PATCH',
        url: `${this.domain}/user/change-password/`,
        headers: { Authorization: `Bearer ${accessToken}` },
        body: {
          oldPassword,
          newPassword,
          confirmNewPassword,
        },
      }),
    );

  profileGet = async () => {
    try {
      const token = await this.getToken();
      const { dateJoined, dateOfBirth, ...data } = await this.createAjaxPromise({
        method: 'GET',
        url: `${this.domain}/user/v2/me/`,
        headers: { Authorization: `Bearer ${token}` },
      });

      return {
        ...data,
        dateJoined: new Date(dateJoined),
        dateOfBirth: new Date(dateOfBirth),
      };
    } catch (error) {
      // TODO: error handling
    }
  };

  updateProfileV2 = profileData =>
    this.getToken().then(accessToken =>
      this.createAjaxPromise({
        method: 'PATCH',
        url: `${this.domain}/user/v2/me/`,
        headers: { Authorization: `Bearer ${accessToken}` },
        body: profileData,
      }),
    );

  checkEmail = email =>
    this.createAjaxPromise({
      method: 'POST',
      url: `${this.domain}/user/v2/email_exists/`,
      body: { email },
    });

  checkUsername = username =>
    this.createAjaxPromise({
      method: 'POST',
      url: `${this.domain}/user/v2/username_exists/`,
      body: { username },
    });

  likePost = post =>
    this.getToken().then(accessToken =>
      fetch(`${this.domain}/post/like/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ post }),
      })
        .then(data =>
          data.status === 204 ? console.warn('status 204') : console.log(`status ${data.status}`),
        )
        .catch(e => console.error('error from unlike', JSON.stringify(e))),
    );

  updateInterests = opportunityInterest =>
    this.getToken().then(accessToken =>
      this.createAjaxPromise({
        method: 'PATCH',
        url: `${this.domain}/user/me/`,
        headers: { Authorization: `Bearer ${accessToken}` },
        body: {
          opportunityInterest,
        },
      }),
    );

  notificationsGetHistory = (pageSize, pageNumber, previousData) => {
    return new Promise((resolve, reject) => {
      this.getToken().then(accessToken =>
        this.createAjaxPromise({
          method: 'GET',
          url: `${this.domain}/notification/history/?page_size=${pageSize}&page=${pageNumber}`,
          headers: { Authorization: `Bearer ${accessToken}` },
        })
          .then(response => {
            if (!previousData) {
              setGlobalState('notificationsState', {
                notifications: response,
                pageNumber: pageNumber,
                pageSize: pageSize,
              });
            }
            resolve(response);
          })
          .catch(error => {
            reject(error);
          }),
      );
    });
  };

  // this should be GET if it doesn't modify
  // notificationsGetRead = () =>
  //   this.getToken().then(accessToken =>
  //     this.createAjaxPromise({
  //       method: 'PATCH',
  //       url: `${this.domain}/notification/read/`,
  //       headers: { Authorization: `Bearer ${accessToken}` },
  //       body: {
  //         idList: [],
  //       },
  //     }),
  //   );

  notificationsUpdateRead = notificationIds =>
    this.getToken().then(accessToken =>
      this.createAjaxPromise({
        method: 'PATCH',
        url: `${this.domain}/notification/read/?id=${notificationIds.join(', ')}`,
        headers: { Authorization: `Bearer ${accessToken}` },
        body: {
          idList: notificationIds,
        },
      }),
    );

  notificationsGetPreferences = () => {
    return new Promise((resolve, reject) => {
      this.getToken()
        .then(accessToken =>
          this.createAjaxPromise({
            method: 'GET',
            url: `${this.domain}/notification/preferences/`,
            headers: { Authorization: `Bearer ${accessToken}` },
          }),
        )
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  };

  notificationsUpdatePreferences = preferences =>
    this.getToken().then(accessToken =>
      this.createAjaxPromise({
        method: 'PATCH',
        url: `${this.domain}/notification/preferences/`,
        headers: { Authorization: `Bearer ${accessToken}` },
        body: preferences,
      }),
    );

  // blockUser = uuid => {
  //   if (!uuid) {
  //     return Promise.reject(Error('No user id provided'));
  //   }

  //   return this.getToken()
  //     .then(accessToken =>
  //       this.createAjaxPromise({
  //         method: 'PATCH',
  //         url: `${this.domain}/user/block/`,
  //         headers: { Authorization: `Bearer ${accessToken}` },
  //         body: { blockedUserId: uuid },
  //       }),
  //     )
  //     .then(profile => profile.blockedUsers);
  // };

  blockUser = uuid => {
    if (!uuid) {
      return Promise.reject(Error('No user id provided'));
    }

    return new Promise((resolve, reject) => {
      this.getToken().then(accessToken =>
        this.createAjaxPromise({
          method: 'PATCH',
          url: `${this.domain}/user/block/`,
          headers: { Authorization: `Bearer ${accessToken}` },
          body: { blockedUserId: uuid },
        })
          .then(response => {
            resolve(response.blockedUsers);
          })
          .catch(error => {
            reject(error);
          }),
      );
    });
  };

  unblockUser = uuid => {
    if (!uuid) {
      return Promise.reject(Error('No user id provided'));
    }

    return new Promise((resolve, reject) => {
      this.getToken().then(accessToken =>
        this.createAjaxPromise({
          method: 'PATCH',
          url: `${this.domain}/user/unblock/`,
          headers: { Authorization: `Bearer ${accessToken}` },
          body: { blockedUserId: uuid },
        })
          .then(response => {
            resolve(response.blockedUsers);
          })
          .catch(error => {
            reject(error);
          }),
      );
    });
  };

  boardGetAll = () =>
    this.getToken()
      .then(accessToken =>
        this.createAjaxPromise({
          method: 'GET',
          url: `${this.domain}/board/list/`,
          headers: { Authorization: `Bearer ${accessToken}` },
        }),
      )
      .then(response =>
        response.map(({ id, ...restBoard }) => ({
          ...restBoard,
          boardId: id,
        })),
      );

  getPosts = (
    boardId = null,
    categoryId = null,
    uid = null,
    keywords = null,
    offset = 0,
    limit = 10,
  ) =>
    this.getToken()
      .then(accessToken => {
        // Construct the URL with query parameters for pagination
        const urlBase = `${this.domain}/post/list/`;
        const queryParams = new URLSearchParams({
          offset: offset.toString(),
          limit: limit.toString(),
        });

        // Add board and author parameters if provided
        if (boardId) {
          queryParams.append('board', boardId);
          queryParams.append('includeGlobal', false);
        }

        if (uid) queryParams.append('author', 'me');

        // Add category parameter if provided
        if (categoryId) queryParams.append('category', categoryId);

        const url = `${urlBase}?${queryParams.toString()}`;
        return this.createAjaxPromise({
          method: 'GET',
          url,
          headers: { Authorization: `Bearer ${accessToken}` },
        });
      })
      .then(response => {
        if (Array.isArray(response)) {
          // Process each post in the array
          return response.map(({ board, ...restPost }) => ({
            ...restPost,
            boardId: board,
          }));
        } else if (response && response.results) {
          // Process each post in the results array
          return response.results.map(({ board, ...restPost }) => ({
            ...restPost,
            posts: response.results,
            boardId: board,
            count: response?.count,
          }));
        } else {
          // Handle unexpected response structure
          console.error('Unexpected response structure:', response);
          return []; // or throw an error, or handle this case as appropriate for your application
        }
      });

  getPostsIncludingGlobal = (boardId = null) =>
    this.getToken()
      .then(accessToken =>
        this.createAjaxPromise({
          method: 'GET',
          url: boardId
            ? `${this.domain}/post/list/?includeGlobal=1&board=${boardId}`
            : `${this.domain}/post/list/`,
          headers: { Authorization: `Bearer ${accessToken}` },
        }),
      )
      .then(response =>
        response.map(({ board, ...restPost }) => ({
          ...restPost,
          boardId: board,
        })),
      );

  getMentionableUsersForBoard = (username, boardId) =>
    this.getToken()
      .then(accessToken =>
        this.createAjaxPromise({
          method: 'GET',
          url: `${this.domain}/board/${boardId}/users/${username}`,
          headers: { Authorization: `Bearer ${accessToken}` },
        }),
      )
      .then(response => {
        return response.map(({ username, avatar, ...values }) => ({
          name: username,
          avatar: avatar?.src,
          ...values,
        }));
      });

  getMentionableUsersForChat = (username, exclude) => {
    const queryParams = new URLSearchParams(exclude.map(value => ['exclude', value]));
    return this.getToken()
      .then(accessToken =>
        this.createAjaxPromise({
          method: 'GET',
          url: `${this.domain}/user/search/${username}?` + queryParams.toString(),
          headers: { Authorization: `Bearer ${accessToken}` },
        }),
      )
      .then(response => {
        return response.map(({ username, avatar, ...values }) => ({
          name: username,
          avatar: avatar?.src,
          ...values,
        }));
      });
  };

  createPost = values =>
    this.getToken().then(accessToken =>
      this.createAjaxPromise({
        method: 'POST',
        url: `${this.domain}/post/create/`,
        headers: { Authorization: `Bearer ${accessToken}` },
        body: {
          language: this.locale,
          ...values,
        },
      }),
    );

  unpublishPost = postId => {
    if (!postId) {
      return Promise.reject(Error('No post id given!'));
    }

    return this.getToken().then(accessToken =>
      this.createAjaxPromise({
        method: 'PATCH',
        url: `${this.domain}/post/${postId}/`,
        headers: { Authorization: `Bearer ${accessToken}` },
        body: {
          isPublished: false,
        },
      }),
    );
  };

  unpublishComment = commentId => {
    if (!commentId) {
      return Promise.reject(Error('No comment id given!'));
    }

    return this.getToken().then(accessToken =>
      this.createAjaxPromise({
        method: 'PATCH',
        url: `${this.domain}/comment/${commentId}/`,
        headers: { Authorization: `Bearer ${accessToken}` },
        body: {
          isPublished: false,
        },
      }),
    );
  };

  commentsForPost = postId => {
    if (!postId) {
      return Promise.reject(Error('No post id given!'));
    }

    return this.getToken().then(accessToken =>
      this.createAjaxPromise({
        method: 'GET',
        url: `${this.domain}/post/${postId}/comments/`,
        headers: { Authorization: `Bearer ${accessToken}` },
      }),
    );
  };

  createComment = ({ postId, content }) => {
    if (!postId) {
      return Promise.reject(Error('No post id given!'));
    }

    if (!content) {
      return Promise.reject(Error('No content to post!'));
    }

    return this.getToken().then(accessToken =>
      this.createAjaxPromise({
        method: 'POST',
        url: `${this.domain}/comment/create/`,
        headers: { Authorization: `Bearer ${accessToken}` },
        body: {
          content,
          language: this.locale,
          post: postId,
        },
      }),
    );
  };

  flagContent = ({ id, type, reason, reasonDescription }) => {
    if (!id) {
      return Promise.reject(Error('No content ID given!'));
    }

    if (!type) {
      return Promise.reject(Error('No content type defined!'));
    }

    if (!reason) {
      return Promise.reject(Error('No reason provided!'));
    }

    const bodyContentId = {};

    if (type === 'comment') {
      bodyContentId.comment = id;
    } else if (type === 'post') {
      bodyContentId.post = id;
    } else {
      return Promise.reject(Error('Content for flagging not recognised'));
    }

    return this.getToken().then(accessToken =>
      this.createAjaxPromise({
        method: 'POST',
        url: `${this.domain}/flagged-content/create/`,
        headers: { Authorization: `Bearer ${accessToken}` },
        body: {
          reason,
          reasonDescription: reasonDescription || '',
          language: this.locale,
          ...bodyContentId,
        },
      }),
    );
  };

  updateAvatar = imageId =>
    this.getToken().then(accessToken =>
      this.createAjaxPromise({
        method: 'PATCH',
        url: `${this.domain}/user/me/`,
        headers: { Authorization: `Bearer ${accessToken}` },
        body: {
          avatar: imageId,
        },
      }),
    );

  updateFirebaseToken = firebaseToken =>
    this.getToken().then(accessToken =>
      this.createAjaxPromise({
        method: 'PUT',
        url: `${this.domain}/notification/firebase/token/`,
        headers: { Authorization: `Bearer ${accessToken}` },
        body: {
          token: firebaseToken,
        },
      }),
    );

  /**
   * Gets a paginated list of messages from specified chat room
   * @param {number} chatId - The unique ID of the chat room
   * @param {import('../types/ChatMessageFetchOptions').ChatMessageFetchOptions} [options] - Options object or url from pagination
   * @returns {Promise<import('../types/ChatMessage').ChatMessagesListResp | void>}
   */
  chatMessages = async (chatId, options) => {
    const query = qs.stringify(options);
    const url =
      options?.url || `${this.domain}/chat-rooms/${chatId}/messages/${query ? `?${query}` : ''}`;

    return this.getToken()
      .then(accessToken =>
        this.createAjaxPromise({
          method: 'GET',
          url: url,
          headers: { Authorization: `Bearer ${accessToken}` },
        }),
      )
      .then(data => ({
        ...data,
        results: data.results.map(({ lastModified, created, ...props }) => ({
          ...props,
          lastModified: new Date(lastModified),
          created: new Date(created),
        })),
      }));
  };

  /**
   * Gets a specific message from a chat
   * @param {number} chatId
   * @param {number} messageId
   * @returns {Promise<import('../types/ChatMessage').ChatMessage>}
   */
  chatMessageById = (chatId, messageId) =>
    this.getToken()
      .then(accessToken =>
        this.createAjaxPromise({
          method: 'GET',
          url: `${this.domain}/chat-rooms/${chatId}/messages/${messageId}/`,
          headers: { Authorization: `Bearer ${accessToken}` },
        }),
      )
      .then(({ lastModified, created, ...data }) => ({
        ...data,
        lastModified: new Date(lastModified),
        created: new Date(created),
      }));

  /**
   * Create a new message in a chat
   * @param {number} chatId
   * @param {string} content
   * @returns {Promise<import('../types/ChatMessage').ChatMessage>}
   */
  createChatMessage = (chatId, content, replyMessageId) =>
    this.getToken()
      .then(accessToken =>
        this.createAjaxPromise({
          method: 'POST',
          url: `${this.domain}/chat-rooms/${chatId}/messages/`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
          body: { content, repliedToMessage: replyMessageId },
        }),
      )
      .then(({ lastModified, created, ...data }) => ({
        ...data,
        lastModified: new Date(lastModified),
        created: new Date(created),
      }));

  /**
   * Gets a list of the available chat rooms
   * @returns {Promise<import('../types/ChatRoom').ChatRoom[]>}
   */
  chatRooms = () =>
    this.getToken()
      .then(accessToken =>
        this.createAjaxPromise({
          method: 'GET',
          url: `${this.domain}/chat-rooms/`,
          headers: { Authorization: `Bearer ${accessToken}` },
        }),
      )
      .then(data =>
        data.map(({ lastModified, created, ...props }) => ({
          ...props,
          lastModified: new Date(lastModified),
          created: new Date(created),
        })),
      );

  /**
   * Gets a specific chat room
   * @param {number} chatId - The unique ID of the chat room
   * @returns {Promise<import('../types/ChatRoom').ChatRoom>}
   */
  chatRoomById = chatId =>
    this.getToken()
      .then(accessToken =>
        this.createAjaxPromise({
          method: 'GET',
          url: `${this.domain}/chat-rooms/${chatId}/`,
          headers: { Authorization: `Bearer ${accessToken}` },
        }),
      )
      .then(({ lastModified, created, ...data }) => ({
        ...data,
        lastModified: new Date(lastModified),
        created: new Date(created),
      }));

  /**
   * Flags a specific chat message
   * @param {number} chatId - The unique ID of the chat room
   * @param {number} messageId - The unique ID of the chat message
   * @param {number} reason - The unique ID of the Taxonomy Flag Reason
   * @param {string} reasonDescription - The unique ID of the Taxonomy Flag Reason
   * @returns {Promise<import('../types/FlaggedChatMessage').FlaggedChatMessage>}
   */
  flagChatMessage = (chatId, messageId, reason, reasonDescription) =>
    this.getToken().then(accessToken =>
      this.createAjaxPromise({
        method: 'POST',
        url: `${this.domain}/chat-rooms/${chatId}/messages/${messageId}/flag/`,
        headers: { Authorization: `Bearer ${accessToken}` },
        body: {
          reason,
          reasonDescription,
          language: this.locale,
        },
      }),
    );

  /**
   * Gets a specific flagged chat message
   * @param {number} chatId - The unique ID of the chat room
   * @param {number} messageId - The unique ID of the chat message
   * @returns {Promise<import('../types/FlaggedChatMessage').FlaggedChatMessage>}
   */
  getFlaggedMessage = (chatId, messageId) =>
    this.getToken().then(accessToken =>
      this.createAjaxPromise({
        method: 'GET',
        url: `${this.domain}/chat-rooms/${chatId}/messages/${messageId}/flag/`,
        headers: { Authorization: `Bearer ${accessToken}` },
      }),
    );

  /**
   * Gets a specific hidden chat message
   * @param {number} chatId - The unique ID of the chat room
   * @param {number} messageId - The unique ID of the chat message
   * @returns {Promise<import('../types/HiddenChatMessage').HiddenChatMessage>}
   */
  getHiddenChatMessage = (chatId, messageId) =>
    this.getToken().then(accessToken =>
      this.createAjaxPromise({
        method: 'GET',
        url: `${this.domain}/chat-rooms/${chatId}/messages/${messageId}/hidden-content/`,
        headers: { Authorization: `Bearer ${accessToken}` },
      }),
    );

  /**
   * Hides a specific chat message
   * @param {number} chatId - The unique ID of the chat room
   * @param {number} messageId - The unique ID of the chat message
   * @param {boolean} isHidden - Whether the message should be hidden or not
   * @returns {Promise<void>}
   */
  hideChatMessage = (chatId, messageId, isHidden) =>
    this.getToken().then(accessToken =>
      this.createAjaxPromise({
        method: 'POST',
        url: `${this.domain}/chat-rooms/${chatId}/messages/${messageId}/is-hidden/`,
        headers: { Authorization: `Bearer ${accessToken}` },
        body: { isHidden },
      }),
    );

  /**
   * Deletes a specific chat message
   * @param {number} chatId - The unique ID of the chat room
   * @param {number} messageId - The unique ID of the chat message
   * @param {boolean} isHidden - Whether the message should be hidden or not
   * @returns {Promise<void>}
   */
  deleteChatMessage = (chatId, messageId) =>
    this.getToken().then(accessToken =>
      this.createAjaxPromise({
        method: 'DELETE',
        url: `${this.domain}/chat-rooms/${chatId}/messages/${messageId}/`,
        headers: { Authorization: `Bearer ${accessToken}` },
      }),
    );

  likeChatMessage = (chatId, message_id) =>
    this.getToken().then(accessToken =>
      this.createAjaxPromise({
        method: 'POST',
        url: `${this.domain}/chat-rooms/${chatId}/messages/${message_id}/like/`,
        headers: { Authorization: `Bearer ${accessToken}` },
      }),
    );

  removeLikeChatMessage = (chatId, message_id) =>
    this.getToken().then(accessToken =>
      this.createAjaxPromise({
        method: 'DELETE',
        url: `${this.domain}/chat-rooms/${chatId}/messages/${message_id}/like/`,
        headers: { Authorization: `Bearer ${accessToken}` },
      }),
    );

  taxonomyCountry = () => this.getTaxonomyNew('country');

  taxonomyGender = () => this.getTaxonomyNew('gender');

  taxonomyEthnicity = () => this.getTaxonomyNew('ethnicity');

  taxonomyMilitaryService = () => this.getTaxonomyNew('military_service');

  taxonomyMilitaryStatus = () => this.getTaxonomyNew('military_status');

  taxonomyFlagReasons = () => this.getTaxonomyNew('flag_reasons');

  taxonomyPastGames = () => this.getTaxonomyNew('past_games');

  taxonomyPastTrials = () => this.getTaxonomyNew('past_trials');

  taxonomyOpportunities = () => this.getTaxonomyNew('opportunity_interest');

  taxonomyMedicalStatus = () => this.getTaxonomyNew('wis_medical_status');

  taxonomyGameInvolvement = () => this.getTaxonomyNew('game_involvement');

  translate = (text, targetLanguage) =>
    this.getToken().then(accessToken =>
      this.createAjaxPromise({
        method: 'POST',
        url: `${this.domain}/translate/`,
        headers: { Authorization: `Bearer ${accessToken}` },
        body: {
          q: text,
          target: targetLanguage,
        },
      }),
    );

  autoTranslate = language =>
    this.getToken().then(accessToken =>
      this.createAjaxPromise({
        method: 'POST',
        url: `${this.domain}/user/auto-translate-preference/`,
        headers: { Authorization: `Bearer ${accessToken}` },
        body: {
          auto_translate_preference: language.autoTranslatePreference,
          language: language.language,
        },
      }),
    );

  getPostCategories = () =>
    this.getToken().then(accessToken =>
      this.createAjaxPromise({
        method: 'GET',
        url: `${this.domain}/post-categories/`,
        headers: { Authorization: `Bearer ${accessToken}` },
      }),
    );

  getPostCategoriesNew = () => {
    return new Promise((resolve, reject) => {
      this.getToken().then(accessToken =>
        this.createAjaxPromise({
          method: 'GET',
          url: `${this.domain}/post-categories/`,
          headers: { Authorization: `Bearer ${accessToken}` },
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          }),
      );
    });
  };
}
