import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FEATURE_FLAGS } from '../util/config';
// import {
//   like as likeDispatch,
//   unLike as unLikeDispatch,
// } from '../redux/modules/likedState';

import { useLiked } from '../util/APIDjango';
import api from '../util/api';

// const mapStateToProps = ({ likedState }) => ({
//   likedPosts: liked.posts,
//   isLiking: liked.updating,
// });

function usePostLike(postId) {
  const [likedState, setLikedState] = useLiked();

  if (!FEATURE_FLAGS.likedPosts) {
    return null;
  }

  const dispatch = useDispatch();
  // const { likedPosts, isLiking } = useSelector(mapStateToProps);

  const likedPosts = likedState.posts;
  const isLiking = likedState.updating;


  const [isLiked, setIsLiked] = useState(likedPosts.includes(postId));
  const [optimisticLiked, setOptimisticLiked] = useState(false);

  // LIKE

  const likeAPost = (postId) => {
    likeDispatch();
    api.likePost(postId)
      .then(() => likeFulfill(postId))
      .catch((error) => likeReject(error));
  }

  const likeDispatch = () => {
    setLikedState({
      ...likedState,
      updating: true,
      error: null,
    })
  }

  const likeFulfill = (postId) => {
    setLikedState({
      ...likedState,
      updating: false,
      posts: [...likedState.posts, postId],
      error: null,
    })
  }

  const likeReject = (err) => {
    setLikedState({
      ...likedState,
      updating: false,
      error: err,
    })
  }

  // UNLIKE

  const unLikeAPost = (postId) => {
    unLikeDispatch();
    api.unLikePost(postId)
      .then(() => unLikeFulfill(postId))
      .catch((error) => unLikeReject(error));
  }

  const unLikeDispatch = () => {
    setLikedState({
      ...likedState,
      updating: true,
      error: null,
    })
  }

  const unLikeFulfill = (postId) => {
    setLikedState({
      ...likedState,
      updating: false,
      posts: likedState.posts.filter(elem => elem !== postId),
      error: null,
    })
  }

  const unLikeReject = (err) => {
    setLikedState({
      ...likedState,
      updating: false,
      error: err,
    })
  }

  useEffect(() => {
    setIsLiked(likedPosts.includes(postId));
  }, [postId, likedPosts]);

  const like = () => {
    setOptimisticLiked(true);
    // dispatch(likeDispatch(postId));
    likeAPost(postId);
  };

  const unLike = () => {
    setOptimisticLiked(false);
    // dispatch(unLikeDispatch(postId));
    unLikeAPost(postId);

  };

  const toggleLiked = () => {
    if (isLiked) {
      unLike();
    } else {
      like();
    }
  };

  return {
    toggleLiked,
    isLiked,
    isLiking,
    optimisticLiked,
  };
}

export default usePostLike;
