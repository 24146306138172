import { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { commentUnpublishDispatch, commentUnpublishReject } from '../redux/modules/comment';
import { useComments } from '../util/APIDjango';
import { MainContext } from '../context/MainContext';
import api from '../util/api';

function useCommentRemove(postId, commentId) {
  const dispatch = useDispatch();
  const [allComments] = useComments();

  const { fetchCommentsForPost } = useContext(MainContext);
  const isUnpublishing = allComments && allComments?.commentsByPostId[postId]?.isUnpublishing;
  const unpublishedSinceLastFetched =
    allComments && allComments?.commentsByPostId[postId]?.unpublishedSinceLastFetched;
  const unPublishError = allComments && allComments?.commentsByPostId[postId]?.unPublishError;
  const [removed, setIsRemoved] = useState(false);
  const [optimisticRemove, setOptimisticRemoved] = useState(false);

  useEffect(() => {
    const isRemoved = unpublishedSinceLastFetched?.includes(commentId);
    setIsRemoved(isRemoved);
  }, [commentId, unpublishedSinceLastFetched]);

  useEffect(() => setOptimisticRemoved(false), [unPublishError]);

  const removeComment = () => {
    setOptimisticRemoved(true);
    api
      .unpublishComment(commentId)
      .then(() => {
        fetchCommentsForPost(postId);
      })
      .catch(error => {
        dispatch(commentUnpublishReject(postId, error.message));
      });
  };

  return {
    removeComment,
    removed,
    isRemoving: isUnpublishing,
    optimisticRemove,
  };
}

export default useCommentRemove;
