import { combineEpics } from 'redux-observable';
import { of, from } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import api from '../../util/api';
import { FULFILL as PROFILE_FETCHED_FULFILL } from '../modules/profile';
import {
  LIKE_DISPATCH,
  likeReject,
  likeFulfill,
  UNLIKE_DISPATCH,
  unLikeReject,
  unLikeFulfill,
  initialLikes,
} from '../modules/liked';

export const initialLikesEpic = (action$, state$) => action$.ofType(PROFILE_FETCHED_FULFILL)
  .pipe(map(() => {
    const { postLikes } = state$.value.profile.profile;
    return initialLikes(postLikes !== [] ? postLikes.map(elem => elem.post) : []);
  }));

export const likeEpic = action$ => action$.ofType(LIKE_DISPATCH)
  .pipe(mergeMap(action => from(api.likePost(action.postId))
    .pipe(
      map(() => likeFulfill(action.postId)),
      catchError(error => of(
        likeReject(error),
      )),
    )));

export const unLikeEpic = action$ => action$.ofType(UNLIKE_DISPATCH)
  .pipe(mergeMap(action => from(api.unLikePost(action.postId))
    .pipe(
      map(() => unLikeFulfill(action.postId)),
      catchError(error => of(
        unLikeReject(error),
      )),
    )));

export default combineEpics(
  initialLikesEpic,
  likeEpic,
  unLikeEpic,
);
