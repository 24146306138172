import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  save as saveDispatch,
  unSave as unSaveDispatch,
} from '../redux/modules/saved';


const mapStateToProps = ({ saved }) => ({
  savedPosts: saved.posts,
  isSaving: saved.updating,
});

function usePostSave(postId) {
  const dispatch = useDispatch();
  const { savedPosts, isSaving } = useSelector(mapStateToProps);
  const [isSaved, setIsSaved] = useState(false);
  const [optimisticSaved, setOptimisticSaved] = useState(false);

  useEffect(() => {
    setIsSaved(savedPosts?.includes(postId));
  }, [postId, savedPosts]);

  const save = () => {
    setOptimisticSaved(true);
    dispatch(saveDispatch(postId));
  };

  const unSave = () => {
    setOptimisticSaved(false);
    dispatch(unSaveDispatch(postId));
  };

  const toggleSaved = () => {
    if (isSaved) {
      unSave();
    } else {
      save();
    }
  };

  return {
    toggleSaved,
    isSaved,
    isSaving,
    optimisticSaved,
  };
}

export default usePostSave;
