export const APP_INIT = 'WIS/app/INIT';
export const appInit = () => ({ type: APP_INIT });

const initialState = {
  error: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
