import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App } from '@capacitor/app';
var AppUrlListener = function () {
    var history = useHistory();
    useEffect(function () {
        App.addListener('appUrlOpen', function (event) {
            var slug = event.url.split('/#').pop();
            if (slug) {
                history.push(slug);
            }
            // If no match, do nothing - let regular routing
            // logic take over
        });
    }, []);
    return null;
};
export default AppUrlListener;
