import { useState, useEffect } from 'react';

/*
  On open stops scrolling by setting height === to viewport.
  On close scrolls back to initial point
*/
function useModal() {
  const [show, setShow] = useState(false);
  const [update, setUpdate] = useState(false);
  const [scroll, setScroll] = useState(0);

  useEffect(() => {
    const { scrollY } = window;
    if (scrollY > 0) {
      setScroll(scrollY);
    }
    const html = document.getElementsByTagName('HTML')[0];
    const body = document.getElementsByTagName('BODY')[0];
    if (show) {
      html.style.maxHeight = '100vh';
      html.style.height = '100%';
      html.style.overflow = 'hidden';
      body.style.maxHeight = '100vh';
      body.style.height = '100%';
      body.style.overflow = 'scrollY';
    } else {
      html.removeAttribute('style');
      body.removeAttribute('style');
      setUpdate(true);
    }

    return () => {
      html.removeAttribute('style');
      body.removeAttribute('style');
      setUpdate(true);
    };
  }, [show]);

  useEffect(() => {
    if (update) {
      const { scrollTo } = window;
      scrollTo({ top: scroll });
      setUpdate(false);
    }
  }, [update]);

  return setShow;
}

export default useModal;
