/* eslint-disable @typescript-eslint/no-unsafe-return */
import { combineEpics } from 'redux-observable';
import { of, from } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import api from '../../util/api';
import {
  GET_CHAT_ROOMS_DISPATCH,
  GET_MESSAGES_FOR_CHAT_DISPATCH,
  GET_NEXT_MESSAGES_FOR_CHAT_DISPATCH,
  GET_PREVIOUS_MESSAGES_FOR_CHAT_DISPATCH,
  getChatRoomsFulfill,
  getChatRoomsReject,
  getMessagesForChatFulfill,
  getMessagesForChatReject,
  getNextMessagesForChatFulfill,
  getNextMessagesForChatReject,
  getPreviousMessagesForChatFulfill,
  getPreviousMessagesForChatReject,
} from '../modules/chat';

export const getMessagesForChatEpic = action$ =>
  action$.ofType(GET_MESSAGES_FOR_CHAT_DISPATCH).pipe(
    mergeMap(({ id, options }) =>
      from(api.chatMessages(id, options)).pipe(
        map(msgs => msgs && getMessagesForChatFulfill(id, msgs)),
        catchError(error => of(getMessagesForChatReject(error))),
      ),
    ),
  );

export const getNextMessagesForChatEpic = action$ =>
  action$.ofType(GET_NEXT_MESSAGES_FOR_CHAT_DISPATCH).pipe(
    mergeMap(action =>
      from(api.chatMessages(action.id, { url: action.url })).pipe(
        map(msgs => getNextMessagesForChatFulfill(action.id, msgs)),
        catchError(error => of(getNextMessagesForChatReject(error))),
      ),
    ),
  );

export const getPreviousMessagesForChatEpic = action$ =>
  action$.ofType(GET_PREVIOUS_MESSAGES_FOR_CHAT_DISPATCH).pipe(
    mergeMap(action =>
      from(api.chatMessages(action.id, { url: action.url })).pipe(
        map(msgs => getPreviousMessagesForChatFulfill(action.id, msgs)),
        catchError(error => of(getPreviousMessagesForChatReject(error))),
      ),
    ),
  );

// export const getChatRoomsEpic = action$ =>
//   action$.ofType(GET_CHAT_ROOMS_DISPATCH).pipe(
//     mergeMap(() =>
//       from(api.chatRooms()).pipe(
//         map(rooms => getChatRoomsFulfill(rooms)),
//         catchError(error => of(getChatRoomsReject(error))),
//       ),
//     ),
//   );

export default combineEpics(
  getMessagesForChatEpic,
  // getChatRoomsEpic,
  getNextMessagesForChatEpic,
  getPreviousMessagesForChatEpic,
);
