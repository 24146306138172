import { of, from } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import api from '../../util/api';
import { DISPATCH, fulfill, reject } from '../modules/taxonomyPastGames';

const taxonomyEpic = action$ => action$.ofType(DISPATCH)
  .pipe(mergeMap(() => from(api.taxonomyPastGames())
    .pipe(
      map(terms => fulfill(terms)),
      catchError(error => of(
        reject(error),
      )),
    )));

export default taxonomyEpic;
