import { combineEpics } from 'redux-observable';
import { of, from } from 'rxjs';
import {
  map,
  mergeMap,
  catchError,
} from 'rxjs/operators';
import api from '../../util/api';
import {
  FULFILL as PROFILE_FETCH_FULFILL,
} from '../modules/profile';
import {
  BLOCK_DISPATCH,
  blockReject,
  blockFulfill,
  UNBLOCK_DISPATCH,
  unBlockReject,
  unBlockFulfill,
  updateBlockedUsers,
  updateBlockedUsersError,
} from '../modules/blocked';


// export const blockEpic = action$ => action$.ofType(BLOCK_DISPATCH)
//   .pipe(mergeMap(action => from(api.blockUser(action.uid))
//     .pipe(
//       map(users => blockFulfill(users)),
//       catchError(error => of(
//         blockReject(error),
//       )),
//     )));

// export const unBlockEpic = action$ => action$.ofType(UNBLOCK_DISPATCH)
//   .pipe(mergeMap(action => from(api.unblockUser(action.uid))
//     .pipe(
//       map(users => unBlockFulfill(users)),
//       catchError(error => of(
//         unBlockReject(error),
//       )),
//     )));


// export const updateBlockedUsersEpic = action$ => action$.ofType(PROFILE_FETCH_FULFILL)
//   .pipe(
//     map(({ profile }) => updateBlockedUsers(profile.blockedUsers)),
//     catchError(error => of(
//       updateBlockedUsersError(error),
//     )),
//   );


export default combineEpics(
  // blockEpic,
  // unBlockEpic,
  // updateBlockedUsersEpic,
);
