import { useContext } from 'react';
import { MainContext } from '../context/MainContext';


function useBoard(boardId) {
  const { boards } = useContext(MainContext);
  const board = boards.find(b => b.boardId === boardId);

  return {
    board,
  };
}

export default useBoard;
