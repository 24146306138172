var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var FILE_ADD_DISPATCH = 'WIS/files/FILE_ADD_DISPATCHED';
export var FILE_ADD_PROGRESS = 'WIS/files/FILE_ADD_PROGRESSED';
export var FILE_ADD_FULFILL = 'WIS/files/FILE_ADD_FULFILLED';
export var FILE_ADD_REJECT = 'WIS/files/FILE_ADD_REJECTED';
export var fileAddDispatch = function (ref, id, name) { return ({ type: FILE_ADD_DISPATCH, ref: ref, id: id, name: name }); };
export var fileAddProgress = function (ref, id, perc) { return ({ type: FILE_ADD_PROGRESS, ref: ref, id: id, perc: perc }); };
export var fileAddFulfill = function (ref, id) { return ({ type: FILE_ADD_FULFILL, ref: ref, id: id }); };
export var fileAddReject = function (ref, id, errorMessage) { return ({ type: FILE_ADD_REJECT, ref: ref, id: id, errorMessage: errorMessage }); };
var initialState = {
    groups: {},
};
var initialFileState = {
    name: '',
    uploadedPerc: 0,
    uploaded: false,
    errorMessage: null,
};
export default function reducer(state, action) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case FILE_ADD_DISPATCH:
            return __assign(__assign({}, state), { groups: __assign(__assign({}, state.groups), (_a = {}, _a[action.ref] = __assign(__assign({}, state.groups[action.ref]), (_b = {}, _b[action.id] = __assign(__assign({}, initialFileState), { name: action.name }), _b)), _a)) });
        case FILE_ADD_PROGRESS:
            return __assign(__assign({}, state), { groups: __assign(__assign({}, state.groups), (_c = {}, _c[action.ref] = __assign(__assign({}, state.groups[action.ref]), (_d = {}, _d[action.id] = __assign(__assign({}, state.groups[action.ref][action.id]), { uploadedPerc: action.perc }), _d)), _c)) });
        case FILE_ADD_FULFILL:
            return __assign(__assign({}, state), { groups: __assign(__assign({}, state.groups), (_e = {}, _e[action.ref] = __assign(__assign({}, state.groups[action.ref]), (_f = {}, _f[action.id] = __assign(__assign({}, state.groups[action.ref][action.id]), { uploaded: true, errorMessage: null }), _f)), _e)) });
        case FILE_ADD_REJECT:
            return __assign(__assign({}, state), { groups: __assign(__assign({}, state.groups), (_g = {}, _g[action.ref] = __assign(__assign({}, state.groups[action.ref]), (_h = {}, _h[action.id] = __assign(__assign({}, state.groups[action.ref][action.id]), { uploaded: false, errorMessage: (action === null || action === void 0 ? void 0 : action.errorMessage) || 'something went wrong uploading.' }), _h)), _g)) });
        default:
            return state;
    }
}
