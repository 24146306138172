var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import FieldLabel from '../FieldLabel/FieldLabel';
import styles from './CheckboxButtonGroup.module.scss';
var CheckboxButtonGroup = function (_a) {
    var label = _a.label, value = _a.value, onChange = _a.onChange, _b = _a.multiSelect, multiSelect = _b === void 0 ? false : _b, renderItems = _a.renderItems, _c = _a.required, required = _c === void 0 ? false : _c, _d = _a.disabled, disabled = _d === void 0 ? false : _d, _e = _a.touched, touched = _e === void 0 ? false : _e, errorText = _a.errorText;
    var getSelectedState = function (optionValue) { return ((multiSelect && Array.isArray(value))
        ? (value && value.includes(optionValue))
        : (value === optionValue)); };
    var handleSelect = function (optionValue) {
        if (onChange && multiSelect && Array.isArray(value)) {
            if (!value) {
                onChange([optionValue]);
            }
            else if (value.includes(optionValue)) {
                var newValue = value.filter(function (v) { return v !== optionValue; });
                onChange(newValue);
            }
            else {
                onChange(value.concat(optionValue));
            }
        }
        else if (onChange && !multiSelect) {
            onChange(optionValue);
        }
    };
    return (_jsxs("div", __assign({ className: styles.wrapper }, { children: [_jsx(FieldLabel, { label: label, required: required, disabled: disabled }), _jsx("div", __assign({ className: styles.items }, { children: renderItems(value, handleSelect, getSelectedState) }))] })));
};
export default CheckboxButtonGroup;
