import React from 'react';

import PropTypes from 'prop-types';
import styles from './DetailTitle.modules.scss';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import { Back } from '../Icons/Back';

const DetailTitle = ({ title, backTo }) => {
  return (
    <ButtonIcon label={title} to={backTo} fullWidth className={styles.backButton} icon={<Back />} />
  );
};

DetailTitle.propTypes = {
  title: PropTypes.string.isRequired,
  backLabel: PropTypes.string,
  backTo: PropTypes.string.isRequired,
};

export default DetailTitle;
