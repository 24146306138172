import { ENVIRONMENT } from './config';
export var LogType;
(function (LogType) {
    LogType["ERROR"] = "error";
    LogType["WARNING"] = "warn";
    LogType["LOG"] = "log";
})(LogType || (LogType = {}));
export var log = function (message, status) {
    if (status === void 0) { status = LogType.LOG; }
    if (ENVIRONMENT !== 'production') {
        var msgArr = typeof message === 'string' ? [message] : message;
        console[status].apply(console, msgArr);
    }
    return null;
};
