/**
 * listens for events caused by a native notification being tapped.
 */
import { updateNotificationRead } from "../modules/notifications";
export var notificationReadListener = function (dispatch) {
    document.addEventListener('pushNotificationRead', function (e) {
        var _a;
        var notificationId = (_a = e.detail) === null || _a === void 0 ? void 0 : _a.notificationId;
        if (notificationId) {
            dispatch(updateNotificationRead([notificationId]));
        }
    });
};
