var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var DISPATCH = 'WIS/taxonomy/flag_reasons/DISPATCHED';
export var FULFILL = 'WIS/taxonomy/flag_reasons/FULFILLED';
export var REJECT = 'WIS/taxonomy/flag_reasons/REJECTED';
export var dispatch = function () { return ({ type: DISPATCH }); };
export var fulfill = function (payload) { return ({ type: FULFILL, payload: payload }); };
export var reject = function (error) { return ({ type: REJECT, error: error }); };
var initialState = {
    terms: [],
    isFetching: false,
    isFetched: false,
    error: null,
};
export default function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case DISPATCH:
            return __assign(__assign({}, state), { isFetching: true });
        case FULFILL:
            return __assign(__assign({}, state), { terms: action.payload, isFetching: false, isFetched: true, error: null });
        case REJECT:
            return __assign(__assign({}, state), { error: action.error, isFetching: false });
        default:
            return state;
    }
}
