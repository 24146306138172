import PropTypes from 'prop-types';
import { authorType } from './author';


export const commentType = {
  id: PropTypes.string.isRequired,
  postId: PropTypes.string.isRequired,
  author: PropTypes.shape(authorType).isRequired,
  created: PropTypes.instanceOf(Date).isRequired,
  content: PropTypes.string.isRequired,
};
