var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var SESSION_RESUME_DISPATCH = 'WIS/auth/SESSION_RESUME_DISPATCH';
export var SESSION_RESUME_FULFILL = 'WIS/auth/SESSION_RESUME_FULFILL';
export var SESSION_RESUME_REJECT = 'WIS/auth/SESSION_RESUME_REJECT';
export var LOGIN_DISPATCH = 'WIS/auth/LOGIN_DISPATCHED';
export var LOGIN_FULFILL = 'WIS/auth/LOGIN_FULFILLED';
export var LOGIN_REJECT = 'WIS/auth/LOGIN_REJECTED';
export var LOGOUT_DISPATCH = 'WIS/auth/LOGOUT_DISPATCHED';
export var LOGOUT_FULFILL = 'WIS/auth/LOGOUT_FULFILLED';
export var LOGOUT_REJECT = 'WIS/auth/LOGOUT_REJECTED';
export var UPDATE_FCM_TOKEN_DISPATCH = 'WIS/auth/UPDATE_FCM_TOKEN_DISPATCHED';
export var UPDATE_FCM_TOKEN_FULFILL = 'WIS/auth/UPDATE_FCM_TOKEN_FULFILLED';
export var UPDATE_FCM_TOKEN_REJECT = 'WIS/auth/UPDATE_FCM_TOKEN_REJECTED';
// export const sessionResume = () => ({ type: SESSION_RESUME_DISPATCH });
// export const sessionResumeFulfill = (uid: number) => ({ type: SESSION_RESUME_FULFILL, uid });
// export const sessionResumeReject = (error: string) => ({ type: SESSION_RESUME_REJECT, error });
// export const login = () => ({ type: LOGIN_DISPATCH });
// export const loginFulfill = (uid: number) => ({ type: LOGIN_FULFILL, uid });
// export const loginReject = (error: string) => ({ type: LOGIN_REJECT, error });
// export const logout = () => ({ type: LOGOUT_DISPATCH });
// export const logoutFulfill = () => ({ type: LOGOUT_FULFILL });
// export const logoutReject = (error: string) => ({ type: LOGOUT_REJECT, error });
export var updateFCMToken = function (token) { return ({ type: UPDATE_FCM_TOKEN_DISPATCH, token: token }); };
export var updateFCMTokenFulfill = function () { return ({ type: UPDATE_FCM_TOKEN_FULFILL }); };
export var updateFCMTokenReject = function (error) { return ({ type: UPDATE_FCM_TOKEN_REJECT, error: error }); };
var initialState = {
    uid: null,
    user: {},
    authenticating: false,
    reAuthenticating: false,
    authenticated: false,
    unauthenticating: false,
    error: null,
    fbcmError: null,
};
export default function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SESSION_RESUME_DISPATCH: {
            return __assign(__assign({}, state), { authenticating: true, reAuthenticating: true, authenticated: false, unauthenticating: false, error: null });
        }
        case SESSION_RESUME_FULFILL: {
            return __assign(__assign({}, state), { uid: action.uid, authenticated: true, authenticating: false, reAuthenticating: false, unauthenticating: false, error: null });
        }
        case SESSION_RESUME_REJECT: {
            return __assign(__assign({}, state), { authenticated: false, authenticating: false, reAuthenticating: false });
        }
        case LOGIN_DISPATCH:
            return __assign(__assign({}, state), { authenticating: true, error: null });
        case LOGIN_FULFILL:
            return __assign(__assign({}, state), { uid: action.uid, authenticated: true, authenticating: false, error: null });
        case LOGIN_REJECT:
            return __assign(__assign({}, state), { authenticating: false, error: action.error });
        case LOGOUT_DISPATCH:
            return __assign(__assign({}, state), { unauthenticating: true, error: null });
        case LOGOUT_FULFILL:
            return __assign(__assign({}, state), { uid: null, authenticated: false, unauthenticating: false, error: null });
        case LOGOUT_REJECT:
            return __assign(__assign({}, state), { unauthenticating: false, error: action.error });
        case UPDATE_FCM_TOKEN_REJECT:
            return __assign(__assign({}, state), { fbcmError: action.error });
        default:
            return state;
    }
}
