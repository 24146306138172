var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getNotificationRedirect } from './getNotificationRedirect';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { Keyboard } from '@capacitor/keyboard';
import { StatusBar, Style } from '@capacitor/status-bar';
import { PushNotifications } from '@capacitor/push-notifications';
import { Browser } from '@capacitor/browser';
import { EmailComposer } from 'capacitor-email-composer';
import { log, LogType } from './logger';
import { OrientationType, ScreenOrientation } from '@capawesome/capacitor-screen-orientation';
var capacitorInit = function () { return __awaiter(void 0, void 0, void 0, function () {
    var device;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!Capacitor.isNativePlatform()) return [3 /*break*/, 2];
                return [4 /*yield*/, ScreenOrientation.lock({ type: OrientationType.PORTRAIT })];
            case 1:
                _a.sent();
                // Upgrading from Cordova to Capacitor broke mailto links
                // When a mailto link is sent to be opened
                // function catches "mailto:" links and instead use capacitor-email-composer to open the default mail app
                window.open = function (url) {
                    if (typeof url === 'string' && url.includes('mailto')) {
                        var email = url.split(':')[1];
                        void EmailComposer.open({
                            to: [email],
                        });
                    }
                    else if (typeof url === 'string') {
                        void Browser.open({ url: url });
                    }
                    return window;
                };
                _a.label = 2;
            case 2:
                StatusBar.getInfo()
                    .then(function (result) {
                    log(String(result));
                })
                    .then(function () {
                    void StatusBar.setOverlaysWebView({
                        overlay: false,
                    });
                    void StatusBar.setBackgroundColor({ color: '#111' });
                    void StatusBar.setStyle({ style: Style.Dark });
                })
                    .then(function () { return StatusBar.getInfo(); })
                    .then(function (result) { return log(String(result)); })
                    .catch(function (error) {
                    log(error.message, LogType.ERROR);
                });
                // On success, we should be able to receive notifications
                void PushNotifications.addListener('registration', function (token) {
                    localStorage.setItem('FCMToken', token.value);
                });
                // Some issue with our setup and push will not work
                void PushNotifications.addListener('registrationError', function (error) {
                    alert("Error on registration: ".concat(JSON.stringify(error)));
                });
                // Show us the notification payload if the app is open on our device
                // PushNotifications.addListener(
                //   'pushNotificationReceived',
                //   (notification) => {
                //     // TODO navigate to relevant page
                //     // alert('Push received: ' + JSON.stringify(notification));
                //   },
                // );
                // Method called when tapping on a notification
                void PushNotifications.addListener('pushNotificationActionPerformed', function (notification) {
                    var payload = notification.notification.data;
                    var pushNoticiationRead = new CustomEvent('pushNotificationRead', {
                        detail: { notificationId: parseInt(payload.id, 10) },
                    });
                    document.dispatchEvent(pushNoticiationRead);
                    window.location.hash = getNotificationRedirect(payload);
                });
                void Keyboard.setAccessoryBarVisible({ isVisible: true });
                return [4 /*yield*/, Device.getInfo()];
            case 3:
                device = _a.sent();
                (function ios12NotchFix(isIos, keyboardPluginExists) {
                    if (!isIos || device.model.indexOf('iPhone') === -1)
                        return false;
                    if (!keyboardPluginExists)
                        throw new Error('This fix depends on keyboard plugin!');
                    var iphoneNumber = Number(device.model.replace('iPhone', '').replace(',', '.'));
                    var viewportEl = document.getElementsByName('viewport')[0];
                    var defaultContent = 'user-scalable=no, width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1';
                    var setCover = function () {
                        // @ts-ignore disable-next-line
                        viewportEl.content = "".concat(defaultContent, ", viewport-fit=cover");
                    };
                    var setFix = function () {
                        // @ts-ignore disable-next-line
                        viewportEl.content = defaultContent;
                    };
                    if (iphoneNumber >= 10.6 || iphoneNumber === 10.3) {
                        /* devices with notch: X, XR, ... */
                        setCover();
                        // Keyboard.addListener('keyboardWillShow', setFix);
                        // Keyboard.addListener('keyboardWillHide', setCover);
                    }
                    return true;
                })(device.platform === 'ios', Capacitor.isPluginAvailable('Keyboard'));
                return [2 /*return*/];
        }
    });
}); };
export default capacitorInit;
