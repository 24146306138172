import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { TransitionMotion, spring } from 'react-motion';
import useModal from '../../hooks/useModal';
import UIContainer from '../UIContainer/UIContainer';
import styles from './ActionSheet.module.scss';

const ActionSheet = ({ show, backDropClick, children }) => {
  const setShow = useModal();
  useEffect(() => {
    setShow(show);
  }, [show]);
  const modalRoot = document.getElementById('action-sheet-root');
  const willLeave = () => ({ opacity: spring(0), y: spring(-200) });
  const willEnter = () => ({ opacity: 0, y: -400 });

  return ReactDOM.createPortal(
    <TransitionMotion
      willLeave={willLeave}
      willEnter={willEnter}
      styles={
        show
          ? [
              {
                key: 'a',
                style: {
                  opacity: spring(1),
                  y: spring(0),
                },
              },
            ]
          : []
      }>
      {items => {
        if (items.length) {
          const { key, style } = items[0];
          return (
            <div
              key={key}
              className={styles.backdrop}
              style={{ opacity: style.opacity }}
              onClick={backDropClick}
              onKeyUp={e => (e.key === 13 ? backDropClick() : null)}
              role="button"
              tabIndex={0}>
              <div
                className={styles.modal}
                onClick={e => {
                  e.stopPropagation();
                }}
                onKeyUp={e => e.stopPropagation()}
                role="button"
                tabIndex={0}
                style={{
                  position: 'relative',
                  bottom: style.y,
                  opacity: style.opacity,
                }}>
                <UIContainer className={styles.sheet}>{children}</UIContainer>
              </div>
            </div>
          );
        }
        return null;
      }}
    </TransitionMotion>,
    modalRoot,
  );
};

ActionSheet.propTypes = {
  show: PropTypes.bool,
  backDropClick: PropTypes.func,
};

ActionSheet.defaultProps = {
  show: false,
  backDropClick: null,
};

export default ActionSheet;
