import React from 'react';
import { Route, Redirect } from 'react-router';
import { useAuth } from '../../util/APIDjango';

const PrivateRoute = ({ component, ...rest }) => {
  const [authState] = useAuth();
  const { authenticated } = authState;
  return (
    <Route
      {...rest}
      render={props =>
        authenticated ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: '/welcome',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
