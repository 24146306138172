import PropTypes from 'prop-types';
import React from 'react';
import styles from './Toggle.module.scss';

const Toggle = ({ className, id, checked, pending, onChange, onClick }) => {
  const wrapperStyles = [styles.wrapper, className].join(' ');
  const toggleStyles = [styles.toggle, pending && styles.pending].join(' ');
  return (
    <div className={wrapperStyles}>
      <div>
        <input
          type="checkbox"
          id={id}
          className={styles.checkbox}
          onChange={e => onChange(e.target.checked)}
          onClick={onClick}
          checked={checked}
        />
        <label htmlFor={id} className={toggleStyles} />
      </div>
    </div>
  );
};

Toggle.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  pending: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

Toggle.defaultProps = {
  pending: false,
};

export default Toggle;
