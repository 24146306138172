/* eslint-disable no-loop-func */
/* eslint-disable no-await-in-loop */
/* eslint-disable prefer-destructuring */
import React, { useState, useRef, useEffect, useContext, createContext } from 'react';
import { Network } from '@capacitor/network';
// import { App } from '@capacitor/app';
// import { BackgroundTask } from '@robingenz/capacitor-background-task';
// import { platform } from '../util/isNative';
import api from '../util/api';
import { log } from '../util/logger.ts';


export const VideoUploadContext = createContext({
  upload: () => null,
  progress: 0,
  uploading: false,
});

export const UploadProvider = ({ children }) => {
  const [file, setVideo] = useState();
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [lastPartUploaded, setLastPartUploaded] = useState(0);
  const [network, setConnected] = useState(true);
  const [serverResp, setServerResp] = useState();
  const [errorFunction, setError] = useState(() => {});
  const [successFunction, setSuccess] = useState(() => {});
  let etags = useRef([]).current;

  const completeUpload = async ({
    video,
    partUploadUrls,
    uuid,
    id,
    createPost,
    cancelPost,
  }) => {
    if (!video) {
      throw Error('No video passed to complete method');
    }

    const chunks = partUploadUrls
      .map(({ partNumber, url }) => api.uploadVideoChunk(chunks[partNumber - 1], url, video.type));

    let { connected } = await Network.getStatus();
    let lastUploaded = lastPartUploaded;
    while (connected && chunks.length > lastUploaded) {
      log('lastUploaded :>> ', lastUploaded);
      const resp = await chunks[lastUploaded]()
        .catch(e => console.error(e));
      const tag = resp !== undefined ? JSON.parse(resp) : resp;
      log('tag :>> ', tag);
      if (tag !== undefined) {
        etags[lastUploaded] = { etag: tag, partNumber: lastUploaded + 1 };
        lastUploaded += 1;
        setProgress((lastUploaded / chunks.length) * 100);
        connected = (await Network.getStatus()).connected;
      } else connected = false;
    }
    connected = (await Network.getStatus()).connected;
    log('etags :>> ', etags);
    setLastPartUploaded(lastUploaded - 1);
    setSuccess(() => createPost);
    setError(() => cancelPost);
    if (connected && etags.length === chunks.length) {
      await api
        .verifyVideoUpload({ videoEtags: etags, uuid })
        .then(() => {
          setVideo();
          setUploading(false);
          setLastPartUploaded(0);
          setServerResp();
          etags = [];
          createPost(id);
        })
        .catch(err => cancelPost(err));
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (network && lastPartUploaded > 0 && uploading) {
      // restart upload
      setTimeout(() => {
        completeUpload({
          video: file,
          id: serverResp.id,
          uuid: serverResp.uuid,
          partUploadUrls: serverResp.partUploadUrls,
          createPost: successFunction,
          cancelPost: errorFunction,
        });
      }, 2000);
    }
  }, [network]);

  useEffect(() => {
    // const appListener = platform === 'ios' ?
    // App.addListener('appStateChange', async (appState) => {
    //   setBackground(!appState.isActive);
    //   if (!appState.isActive && uploading) {
    //     if (network) {
    //       // Background Task
    //       const taskId = await BackgroundTask.beforeExit(async () => {
    //         // Run code
    //         await completeUpload(file, serverResp.partUploadUrls, serverResp.uuid);
    //         BackgroundTask.finish({ taskId });
    //       });
    //     }
    //   }
    // }) : { remove: () => {} };

    const networkListener = Network.addListener(
      'networkStatusChange',
      async (networkStatus) => {
        setConnected(networkStatus.connected);
      },
    );

    return () => {
      // appListener.remove();
      networkListener.remove();
    };
  }, []);

  const upload = async (video, createPost, cancelPost) => {
    setUploading(true);
    setVideo(video);
    const resp = await api
      .uploadVideoToAPI({ video })
      .catch(err => cancelPost(err));
    log('resp from first req :>> ', resp);
    // if (platform === 'ios') successFunction();
    setServerResp({ ...resp });
    completeUpload({
      video,
      id: resp.id,
      uuid: resp.uuid,
      partUploadUrls: resp.partUploadUrls,
      createPost,
      cancelPost,
    });
  };

  const contextValue = {
    upload,
    progress,
    uploading,
  };

  return (
    <VideoUploadContext.Provider value={contextValue}>
      {children}
    </VideoUploadContext.Provider>
  );
};

export const useVideoUpload = () => useContext(VideoUploadContext);
