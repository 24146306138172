import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import ar_IQ from '../assets/translations/ar_IQ.json';
import ar_JO from '../assets/translations/ar_JO.json';
import da from '../assets/translations/da.json';
import de from '../assets/translations/de.json';
import en_GB from '../assets/translations/en_UK.json';
import en_US from '../assets/translations/en_US.json';
import es_ES from '../assets/translations/es_ES.json';
import et from '../assets/translations/et.json';
import fa from '../assets/translations/fa.json';
import fr from '../assets/translations/fr.json';
import fr_BE from '../assets/translations/fr_BE.json';
import fr_CA from '../assets/translations/fr_CA.json';
import it from '../assets/translations/it.json';
import iw from '../assets/translations/iw.json';
import ka_GE from '../assets/translations/ka_GE.json';
import ko from '../assets/translations/ko.json';
import nl from '../assets/translations/nl.json';
import nl_BE from '../assets/translations/nl_BE.json';
import pl from '../assets/translations/pl.json';
import ps from '../assets/translations/ps.json';
import ro from '../assets/translations/ro.json';
import uk from '../assets/translations/uk.json';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import dayjs from 'dayjs';

import AdvancedFormat from 'dayjs/plugin/advancedFormat';

import UpdateLocale from 'dayjs/plugin/updateLocale';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import RelativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(LocalizedFormat);
dayjs.extend(AdvancedFormat);
dayjs.extend(RelativeTime);
dayjs.extend(UpdateLocale);

dayjs.updateLocale('en', {
  formats: {
    L: 'MM/DD/YYYY',
  },
});

export const LANGUAGE_KEY = 'applicationLanguage';

export const languageKeys = [
  'ar-IQ',
  'ar-JO',
  da,
  de,
  'en-GB',
  'en',
  'es-ES',
  et,
  fa,
  fr,
  'fr-BE',
  'fr-CA',
  it,
  iw,
  'ka-GE',
  ko,
  nl,
  'nl-BE',
  pl,
  ps,
  ro,
  uk,
];

// Fallback to English
const fallbackLng = 'en-GB';

export const detectLanguage = language =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  languageKeys.includes(language)
    ? language
    : language.indexOf('-')
      ? language.split('-')[0].toLowerCase()
      : language;

export const loadLanguage = async language => {
  const languageKey = detectLanguage(language);
  try {
    await import(`dayjs/locale/${languageKey.toLowerCase()}.js`);
    dayjs.locale(languageKey);
  } catch (error) {
    if (language.indexOf('-') > 0) {
      void loadLanguage(language.split('-')[0].toLowerCase());
    } else {
      dayjs.locale(fallbackLng);
    }
  }
};

const languageDetector = {
  type: 'languageDetector',
  async: true, // Enables async language detection

  detect: async callback => {
    try {
      // Check for language preference in localStorage
      let savedLanguage = localStorage.getItem(LANGUAGE_KEY);
      if (savedLanguage) {
        void loadLanguage(savedLanguage);
        callback(savedLanguage);
        return;
      }

      const isNative = Capacitor.isNativePlatform();
      if (isNative) {
        const deviceLanguage = await Device.getLanguageTag();
        void loadLanguage(deviceLanguage.value);
        callback(deviceLanguage.value);
        return;
      }

      // Detect language from browser settings
      const browserLanguage = navigator.language || navigator.languages[0];
      if (browserLanguage) {
        void loadLanguage(browserLanguage);
        callback(browserLanguage);
        return;
      }
      void loadLanguage(fallbackLng);
      callback(fallbackLng);
    } catch (error) {
      void loadLanguage(fallbackLng);
      callback(fallbackLng);
    }
  },
  init: () => {
    // No initialization needed
  },
  cacheUserLanguage: lang => {
    localStorage.setItem(LANGUAGE_KEY, lang);
  },
  name: 'languageDetector',
};

export default i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      'ar-IQ': { translations: ar_IQ },
      'ar-JO': { translations: ar_JO },
      da: { translations: da },
      de: { translations: de },
      'en-GB': { translations: en_GB },
      en: { translations: en_US },
      'es-ES': { translations: es_ES },
      et: { translations: et },
      fa: { translations: fa },
      fr: { translations: fr },
      'fr-BE': { translations: fr_BE },
      'fr-CA': { translations: fr_CA },
      it: { translations: it },
      iw: { translations: iw },
      'ka-GE': { translations: ka_GE },
      ko: { translations: ko },
      nl: { translations: nl },
      'nl-BE': { translations: nl_BE },
      pl: { translations: pl },
      ps: { translations: ps },
      ro: { translations: ro },
      uk: { translations: uk },
    },
    order: ['languageDetector'],
    fallbackLng,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      wait: true,
    },
  });

i18n.on('languageChanged', language => {
  void loadLanguage(language);
  document.body.dir = i18n.dir();
});
