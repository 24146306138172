import React from 'react';
import { Route } from 'react-router';
import { isNative } from '../../util/isNative';


const WebRoute = ({ component, nonWeb, ...rest }) => {
  if (!isNative) {
    return (
      <Route
        {...rest}
        render={props => React.createElement(component, props)}
      />
    );
  }
  return (
    <Route
      {...rest}
      render={props => React.createElement(nonWeb, props)}
    />
  );
};

export default WebRoute;
