import '../assets/images/default-avatar.svg';
import '../assets/images/post-example-1.jpg';
import '../assets/images/post-example-2.jpg';
import '../assets/images/post-example-3.jpg';
import '../assets/images/opportunity-example-1.jpg';
import '../assets/images/opportunity-example-2.jpg';
import '../assets/images/opportunity-example-3.jpg';

import boardsJSON from './boards';
import commentsJSON from './comments';
import countryTaxonomyJSON from './countryTaxonomy';
import postsJSON from './posts';

const loadToken = name =>
  Promise.resolve().then(() => localStorage.getItem(name));

const removeToken = name =>
  Promise.resolve().then(() => localStorage.removeItem(name));

const apiStub = ({ locale }) => {
  const blockedUsersStub = [
    {
      username: 'John777',
      uuid: 4,
      avatar: {
        id: 'o8g807g986g',
        src: 'images/default-avatar.svg',
      },
    },
    {
      username: 'Robert111',
      uuid: 5,
      avatar: {
        id: 'o8g807g986g',
        src: 'images/default-avatar.svg',
      },
    },
    {
      username: 'Daniel654',
      uuid: 6,
      avatar: {
        id: 'o8g807g986g',
        src: 'images/default-avatar.svg',
      },
    },
  ];

  const stubUser = {
    uuid: 1,
    username: 'Sample User',
    avatar: {
      id: 'o8g807g986g',
      src: 'images/default-avatar.svg',
    },
    email: 'sample-user@gmail.com',
    blockedUsers: blockedUsersStub,
    firstName: 'Sample',
    lastName: 'User',
    phone: '07429111222',
    country: {
      id: 236,
      name: 'GBR',
      description: 'United Kingdom',
      vocabulary: 'country',
      isOther: false,
      order: 236,
    },
    dateOfBirth: '1989-05-17T10:10:13.000Z',
    gender: {
      id: 4,
      isOther: false,
      order: 0,
      name: 'Male',
      description: null,
      vocabulary: 'gender',
    },
    // ethnicity: { id: 4, isOther: false, order: 0, name: 'White British', description: null, vocabulary: 'ethnicity' },
    address: 'Sample Road, Sample Town, Sample County, SM01 1A1',
    msNo: '1234567890',
    militaryStatus: {
      id: 4,
      isOther: false,
      order: 0,
      name: 'Serving',
      description: null,
      vocabulary: 'military_status',
    },
    militaryService: {
      id: 4,
      isOther: false,
      order: 0,
      name: 'Army',
      description: null,
      vocabulary: 'military_service',
    },
    gameInvolvement: [
      {
        id: 1,
        isOther: false,
        order: 0,
        name: 'Competed',
        description: null,
        vocabulary: 'game_involvement',
      },
    ],
    pastGames: [
      {
        id: 6,
        isOther: false,
        order: 2,
        name: '2017',
        description: null,
        vocabulary: 'past_games',
      },
      {
        id: 7,
        isOther: false,
        order: 3,
        name: '2018',
        description: null,
        vocabulary: 'past_games',
      },
    ],
    pastTrials: [
      {
        id: 8,
        isOther: false,
        order: 4,
        name: '2019',
        description: null,
        vocabulary: 'past_trials',
      },
      {
        id: 9,
        isOther: false,
        order: 5,
        name: '2020',
        description: null,
        vocabulary: 'past_trials',
      },
    ],
    medicalStatus: {
      id: 1,
      isOther: false,
      order: 0,
      name: 'Medically retired',
      description: null,
      vocabulary: 'medical_status',
    },
    medicalStatusOther: null,
    opportunityInterest: [
      {
        id: 4,
        isOther: false,
        order: 0,
        name: 'Employment',
        description: null,
        vocabulary: 'opportunity_interest',
      },
      {
        id: 5,
        isOther: false,
        order: 1,
        name: 'Sport for recovery',
        description: null,
        vocabulary: 'opportunity_interest',
      },
    ],
  };

  const signIn = (email, password) =>
    new Promise(res => {
      setTimeout(
        () =>
          res({
            token: '123456789',
          }),
        1200,
      );
    });

  const resumeSession = new Promise(res =>
    loadToken('accessToken').then(token =>
      setTimeout(() => res({ token }), 1200),
    ),
  );

  const signOut = () =>
    new Promise(res => removeToken('accessToken').then(setTimeout(res, 1200)));

  const signUp = values =>
    new Promise(res => {
      console.log(values);
      setTimeout(res, 800);
    });

  const forgotPassword = email =>
    new Promise(res => {
      console.log(email);
      setTimeout(res, 800);
    });

  const resetPassword = values =>
    new Promise(res => {
      console.log(values?.newPassword);
      setTimeout(res, 800);
    });

  const uploadFile = file =>
    new Promise(res => {
      console.log(file);
      setTimeout(() => res('stub-file-url'), 800);
    });

  const profileGet = () =>
    new Promise(res => setTimeout(() => res(stubUser), 1000));

  const boardGetAll = () =>
    new Promise(res => {
      setTimeout(() => res(boardsJSON), 800);
    }).then(response =>
      response.map(({ id, ...restBoard }) => ({
        ...restBoard,
        boardId: id,
      })),
    );

  const getPosts = () =>
    new Promise(res => {
      setTimeout(() => res(postsJSON), 800);
    });

  const getCommentsForPost = postId =>
    Object.values(commentsJSON).filter(comment => comment.post === postId);

  const commentsForPost = postId =>
    new Promise(res => setTimeout(() => res(getCommentsForPost(postId)), 1000));

  const createPost = values =>
    new Promise(res => {
      const newValues = {
        language: locale,
        ...values,
      };
      console.log(newValues);
      setTimeout(res, 800);
    });

  const createComment = values =>
    new Promise(res => {
      console.log(values);
      setTimeout(res, 800);
    });

  const blockUser = uuid =>
    new Promise(res => {
      blockedUsersStub.push({
        username: 'stub-username',
        uuid,
        avatar: { id: 'o8g807g986g', src: 'images/default-avatar.svg' },
      });
      setTimeout(() => res([...blockedUsersStub]), 800);
    });

  const unblockUser = uuid =>
    new Promise(res => {
      setTimeout(() => {
        const indexToRemove = blockedUsersStub.findIndex(
          user => user.uuid === uuid,
        );
        blockedUsersStub.splice(indexToRemove, 1);
        res(blockedUsersStub);
      }, 800);
    });

  const savePost = id =>
    new Promise(res => {
      setTimeout(() => res([id]), 800);
    });

  const unSavePost = id =>
    new Promise(res => {
      setTimeout(() => res([]), 800);
    });

  const likePost = id =>
    new Promise(res => {
      setTimeout(() => res([id]), 200);
    });

  const unLikePost = id =>
    new Promise(res => {
      setTimeout(() => res([]), 200);
    });

  const flagContent = values =>
    new Promise(res => {
      setTimeout(() => res(), 800);
    });

  const taxonomyEthnicity = () =>
    new Promise(res => {
      const taxonomy = [
        {
          id: 4,
          isOther: false,
          order: 0,
          name: 'White British',
          description: null,
          vocabulary: 'ethnicity',
        },
        {
          id: 5,
          isOther: false,
          order: 1,
          name: 'Black British',
          description: null,
          vocabulary: 'ethnicity',
        },
        {
          id: 6,
          isOther: false,
          order: 2,
          name: 'British Asian',
          description: null,
          vocabulary: 'ethnicity',
        },
        {
          id: 7,
          isOther: false,
          order: 3,
          name: 'British Indian',
          description: null,
          vocabulary: 'ethnicity',
        },
      ];
      setTimeout(() => res(taxonomy), 600);
    });

  const taxonomyGender = () =>
    new Promise(res => {
      const taxonomy = [
        {
          id: 4,
          isOther: false,
          order: 0,
          name: 'Male',
          description: null,
          vocabulary: 'gender',
        },
        {
          id: 5,
          isOther: false,
          order: 1,
          name: 'Female',
          description: null,
          vocabulary: 'gender',
        },
        {
          id: 6,
          isOther: false,
          order: 2,
          name: 'Transgender Male',
          description: null,
          vocabulary: 'gender',
        },
        {
          id: 7,
          isOther: false,
          order: 3,
          name: 'Transgender Female',
          description: null,
          vocabulary: 'gender',
        },
        {
          id: 8,
          isOther: false,
          order: 4,
          name: 'Gender Variant/Non-Conforming',
          description: null,
          vocabulary: 'gender',
        },
        {
          id: 9,
          isOther: false,
          order: 5,
          name: 'Prefer not to answer',
          description: null,
          vocabulary: 'gender',
        },
      ];
      setTimeout(() => res(taxonomy), 600);
    });

  const taxonomyCountry = () =>
    new Promise(res => {
      const taxonomy = countryTaxonomyJSON;
      setTimeout(() => res(taxonomy), 600);
    });

  const taxonomyMilitaryStatus = () =>
    new Promise(res => {
      const taxonomy = [
        {
          id: 4,
          isOther: false,
          order: 0,
          name: 'Serving',
          description: null,
          vocabulary: 'military_status',
        },
        {
          id: 5,
          isOther: false,
          order: 1,
          name: 'Veteran',
          description: null,
          vocabulary: 'military_status',
        },
      ];
      setTimeout(() => res(taxonomy), 600);
    });

  const taxonomyFlagReasons = () =>
    new Promise(res => {
      const taxonomy = [
        {
          id: 4,
          isOther: false,
          order: 0,
          name: 'Abusive',
          description: null,
          vocabulary: 'flag_reasons',
        },
        {
          id: 5,
          isOther: false,
          order: 1,
          name: 'Spam',
          description: null,
          vocabulary: 'flag_reasons',
        },
        {
          id: 6,
          isOther: false,
          order: 2,
          name: 'Hate speech',
          description: null,
          vocabulary: 'flag_reasons',
        },
        {
          id: 7,
          isOther: false,
          order: 3,
          name: 'Terrorism',
          description: null,
          vocabulary: 'flag_reasons',
        },
        {
          id: 8,
          isOther: false,
          order: 4,
          name: 'Nudity',
          description: null,
          vocabulary: 'flag_reasons',
        },
        {
          id: 9,
          isOther: false,
          order: 5,
          name: 'Other',
          description: null,
          vocabulary: 'flag_reasons',
        },
      ];
      setTimeout(() => res(taxonomy), 600);
    });

  const taxonomyMilitaryService = () =>
    new Promise(res => {
      const taxonomy = [
        {
          id: 4,
          isOther: false,
          order: 0,
          name: 'Army',
          description: null,
          vocabulary: 'military_service',
        },
        {
          id: 5,
          isOther: false,
          order: 1,
          name: 'Navy',
          description: null,
          vocabulary: 'military_service',
        },
        {
          id: 6,
          isOther: false,
          order: 2,
          name: 'Air Force',
          description: null,
          vocabulary: 'military_service',
        },
        {
          id: 7,
          isOther: false,
          order: 3,
          name: 'Marines',
          description: null,
          vocabulary: 'military_service',
        },
      ];
      setTimeout(() => res(taxonomy), 600);
    });

  const taxonomyPastGames = () =>
    new Promise(res => {
      const taxonomy = [
        {
          id: 4,
          isOther: false,
          order: 0,
          name: '2014',
          description: null,
          vocabulary: 'past_games',
        },
        {
          id: 5,
          isOther: false,
          order: 1,
          name: '2016',
          description: null,
          vocabulary: 'past_games',
        },
        {
          id: 6,
          isOther: false,
          order: 2,
          name: '2017',
          description: null,
          vocabulary: 'past_games',
        },
        {
          id: 7,
          isOther: false,
          order: 3,
          name: '2018',
          description: null,
          vocabulary: 'past_games',
        },
        {
          id: 8,
          isOther: false,
          order: 4,
          name: '2020',
          description: null,
          vocabulary: 'past_games',
        },
      ];
      setTimeout(() => res(taxonomy), 600);
    });

  const taxonomyPastTrials = () =>
    new Promise(res => {
      const taxonomy = [
        {
          id: 5,
          isOther: false,
          order: 1,
          name: '2016',
          description: null,
          vocabulary: 'past_trials',
        },
        {
          id: 6,
          isOther: false,
          order: 2,
          name: '2017',
          description: null,
          vocabulary: 'past_trials',
        },
        {
          id: 7,
          isOther: false,
          order: 3,
          name: '2018',
          description: null,
          vocabulary: 'past_trials',
        },
        {
          id: 8,
          isOther: false,
          order: 4,
          name: '2019',
          description: null,
          vocabulary: 'past_trials',
        },
        {
          id: 9,
          isOther: false,
          order: 5,
          name: '2020',
          description: null,
          vocabulary: 'past_trials',
        },
      ];
      setTimeout(() => res(taxonomy), 600);
    });

  const taxonomyOpportunities = () =>
    new Promise(res => {
      const taxonomy = [
        {
          id: 4,
          isOther: false,
          order: 0,
          name: 'Employment',
          description: null,
          vocabulary: 'opportunity_interest',
        },
        {
          id: 5,
          isOther: false,
          order: 1,
          name: 'Sport for recovery',
          description: null,
          vocabulary: 'opportunity_interest',
        },
        {
          id: 6,
          isOther: false,
          order: 2,
          name: 'Speaker',
          description: null,
          vocabulary: 'opportunity_interest',
        },
        {
          id: 7,
          isOther: false,
          order: 3,
          name: 'Event tickets',
          description: null,
          vocabulary: 'opportunity_interest',
        },
        {
          id: 8,
          isOther: false,
          order: 4,
          name: 'Research participation',
          description: null,
          vocabulary: 'opportunity_interest',
        },
        {
          id: 9,
          isOther: false,
          order: 5,
          name: 'Support fundraising',
          description: null,
          vocabulary: 'opportunity_interest',
        },
        {
          id: 10,
          isOther: false,
          order: 6,
          name: 'Volunteering',
          description: null,
          vocabulary: 'opportunity_interest',
        },
      ];
      setTimeout(() => res(taxonomy), 600);
    });

  const taxonomyMedicalStatus = () =>
    new Promise(res => {
      const taxonomy = [
        {
          id: 1,
          isOther: false,
          order: 0,
          name: 'Medically retired',
          description: null,
          vocabulary: 'medical_status',
        },
        {
          id: 2,
          isOther: false,
          order: 1,
          name: 'Medically downgraded',
          description: null,
          vocabulary: 'medical_status',
        },
        {
          id: 3,
          isOther: false,
          order: 2,
          name: 'Medically discharged',
          description: null,
          vocabulary: 'medical_status',
        },
        {
          id: 4,
          isOther: true,
          order: 3,
          name: 'Other',
          description: null,
          vocabulary: 'medical_status',
        },
      ];
      setTimeout(() => res(taxonomy), 600);
    });

  const taxonomyGameInvolvement = () =>
    new Promise(res => {
      const taxonomy = [
        {
          id: 1,
          isOther: false,
          order: 0,
          name: 'Competed',
          description: null,
          vocabulary: 'game_involvement',
        },
        {
          id: 2,
          isOther: false,
          order: 1,
          name: 'Trialed',
          description: null,
          vocabulary: 'game_involvement',
        },
      ];
      setTimeout(() => res(taxonomy), 600);
    });
  const chatRooms = () =>
    new Promise(res => {
      const rooms = [
        {
          id: 1,
          name: 'Tennis',
          description: 'A chat about Tennis',
          created: new Date('2023-05-31T13:23:00.524097Z'),
          lastModified: new Date('2023-05-31T13:23:00.524137Z'),
        },
        {
          id: 2,
          name: 'Golf',
          description: 'A chat about Golf',
          created: new Date('2023-05-31T13:23:00.524097Z'),
          lastModified: new Date('2023-05-31T13:23:00.524137Z'),
        },
        {
          id: 3,
          name: 'Running',
          description: 'A chat about Running',
          created: new Date('2023-05-31T13:23:00.524097Z'),
          lastModified: new Date('2023-05-31T13:23:00.524137Z'),
        },
      ];
      setTimeout(() => res(rooms), 600);
    });

  const chatRoomById = chatId =>
    new Promise(res => {
      const rooms = [
        {
          id: 1,
          name: 'Tennis',
          description: 'A chat about Tennis',
          created: new Date('2023-05-31T13:23:00.524097Z'),
          lastModified: new Date('2023-05-31T13:23:00.524137Z'),
        },
        {
          id: 2,
          name: 'Golf',
          description: 'A chat about Golf',
          created: new Date('2023-05-31T13:23:00.524097Z'),
          lastModified: new Date('2023-05-31T13:23:00.524137Z'),
        },
        {
          id: 3,
          name: 'Running',
          description: 'A chat about Running',
          created: new Date('2023-05-31T13:23:00.524097Z'),
          lastModified: new Date('2023-05-31T13:23:00.524137Z'),
        },
      ];
      setTimeout(() => res(rooms.find(({ id }) => id === chatId)), 600);
    });
  /**
   * Gets a paginated list of messages from specified chat room
   * @param {number} chatId - The unique ID of the chat room
   * @param {import('../types/ChatMessageFetchOptions').ChatMessageFetchOptions} [options] - Options object or url from pagination
   * @returns {Promise<import('../types/ChatMessage').ChatMessagesListResp>}
   */
  const chatMessages = (chatId, _) =>
    new Promise(res => {
      const rooms = [
        [
          {
            id: 1,
            author: { id: 93, username: 'CharlieJ', avatar: stubUser.avatar },
            content: 'hello there',
            created: new Date('2023-05-31T13:21:00.524097Z'),
            lastModified: new Date('2023-05-31T13:21:00.524137Z'),
          },
          {
            id: 2,
            author: { id: 78, username: 'Amy Admin', avatar: stubUser.avatar },
            content: 'alright?',
            created: new Date('2023-05-31T13:22:00.524097Z'),
            lastModified: new Date('2023-05-31T13:22:00.524137Z'),
          },
          {
            id: 3,
            author: { id: 93, username: 'CharlieJ', avatar: stubUser.avatar },
            content: 'how are you?',
            created: new Date('2023-05-31T13:23:00.524097Z'),
            lastModified: new Date('2023-05-31T13:23:00.524137Z'),
          },
        ],
        [
          {
            id: 4,
            author: { id: 93, username: 'CharlieJ', avatar: stubUser.avatar },
            content: 'hello there',
            created: new Date('2023-05-31T13:21:00.524097Z'),
            lastModified: new Date('2023-05-31T13:21:00.524137Z'),
          },
          {
            id: 5,
            author: { id: 78, username: 'Amy Admin', avatar: stubUser.avatar },
            content: 'alright?',
            created: new Date('2023-05-31T13:22:00.524097Z'),
            lastModified: new Date('2023-05-31T13:22:00.524137Z'),
          },
          {
            id: 6,
            author: { id: 93, username: 'CharlieJ', avatar: stubUser.avatar },
            content: 'how are you?',
            created: new Date('2023-05-31T13:23:00.524097Z'),
            lastModified: new Date('2023-05-31T13:23:00.524137Z'),
          },
        ],
      ];
      setTimeout(() =>
        res({ next: null, previous: null, results: rooms[chatId - 1] }, 600),
      );
    });

  return {
    signIn,
    resumeSession,
    signOut,
    signUp,
    forgotPassword,
    resetPassword,
    uploadFile,
    profileGet,
    boardGetAll,
    getPosts,
    commentsForPost,
    createPost,
    createComment,
    blockUser,
    unblockUser,
    savePost,
    unSavePost,
    likePost,
    unLikePost,
    flagContent,
    taxonomyEthnicity,
    taxonomyGender,
    taxonomyCountry,
    taxonomyMilitaryStatus,
    taxonomyFlagReasons,
    taxonomyMilitaryService,
    taxonomyPastGames,
    taxonomyPastTrials,
    taxonomyOpportunities,
    taxonomyMedicalStatus,
    taxonomyGameInvolvement,
    chatRooms,
    chatRoomById,
    chatMessages,
  };
};

export default apiStub;
