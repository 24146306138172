import React, { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';

import Board from '../types/Board';
import { commentGetByPostIdDispatch } from '../redux/modules/comment';
import { useBoards, useComments } from '../util/APIDjango';
import api from '../util/api';

import { usePosts, usePostCategories } from '../util/APIDjango';
import { isNative } from '../util/isNative';
import { UpdateApp } from '../components/UpdateApp/UpdateApp';

const mapStateToProps = ({ boards, posts, comments, postCategories }) => ({
  // boards: boards.boards,
  // posts: posts.posts,
  // postCategories: postCategories.categories,
  // postFetching: posts.isFetching,
  // fetchError: posts.fetchError,
  // createError: posts.createError,
  // boards: boards.boards,
  // commentsByPostId: comments.commentsByPostId,
});

export const MainContext = createContext();

export const MainProvider = ({ children }) => {
  const [allPosts, setAllPosts] = usePosts();
  const [allPostCategories, setAllPostCategories] = usePostCategories();
  const [allComments, setAllComments] = useComments();
  const [showUpdate, setShowUpdate] = useState(false);

  const posts = allPosts.posts;
  const postCategories = allPostCategories.categories;
  const postFetching = allPosts.isFetching;
  const fetchError = allPosts.fetchError;
  const createError = allPosts.createError;

  const dispatch = useDispatch();
  const [boardsData, setBoardsData] = useBoards();

  const getBoardsData = () => setBoardsData({ ...boardsData, isFetching: true });

  const boardsDataFulfill = boardsData =>
    setBoardsData({
      ...boardsData,
      boards: boardsData,
      isFetching: false,
      isFetched: true,
      fetchError: null,
    });

  const boardsDataReject = error =>
    setBoardsData({ ...boardsData, fetchError: error, isFetching: false });

  useEffect(() => {
    // if (isNative) {
    //   AppUpdate.getAppUpdateInfo()
    //     .then(result => {
    //       setShowUpdate(result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE);
    //     })
    //     .catch(() => {
    //       //
    //     });
    // }

    getBoardsData();
    api
      .boardGetAll()
      .then(boardsData => {
        boardsDataFulfill(boardsData);
      })
      .catch(err => {
        boardsDataReject(err.message);
      });
  }, []);

  const {
    // boards,
    // posts,
    // postCategories,
    // postFetching,
    // commentsByPostId,
    // fetchError,
    // createError,
  } = useSelector(mapStateToProps);

  const defaultBoard = boardsData.boards.find(board => board.isDefaultForUser);

  const [data, setData] = useState({
    menuOpen: false,
  });

  const [createErrorConfirmed, setCreateErrorConfirmed] = useState(false);

  // const fetchCommentsForPost = postId =>
  //   dispatch(commentGetByPostIdDispatch(postId));

  const commentsForPost = postId => allComments.commentsByPostId[postId] || [];

  const fetchCommentsForPost = postId => {
    fetchCommentsDispatch(postId);
    api
      .commentsForPost(postId)
      .then(comments => {
        const sortedComments = comments.sort((a, b) => {
          const dateA = new Date(a.lastModified);
          const dateB = new Date(b.lastModified);
          return dateB - dateA;
        });
        fetchCommentsFulfill(postId, sortedComments);
      })
      .catch(error => {
        fetchCommentsReject(postId, error);
      });
  };

  const fetchCommentsDispatch = postId => {
    const currentComments = allComments.commentsByPostId[postId]
      ? allComments.commentsByPostId[postId].comments
      : [];
    const commentsForPost = {};
    commentsForPost[postId] = {
      comments: [...(currentComments || [])],
      isFetched: false,
      isFetching: true,
    };

    return {
      ...allComments,
      commentsByPostId: {
        ...allComments.commentsByPostId,
        ...commentsForPost,
      },
    };
  };

  const fetchCommentsFulfill = (postId, sortedComments) => {
    const commentsForPost = {};
    /*
      ensure correct by filtering comments by postId
      then rename key 'post' to 'postId'
    */
    const sanitizedComments = sortedComments
      .filter(comment => comment.post === postId)
      .map(({ post, ...rest }) => ({ ...rest, postId: post }));

    commentsForPost[postId] = {
      comments: sanitizedComments,
      isFetched: true,
      isFetching: false,
      lastFetched: Date.now(),
      unpublishedSinceLastFetched: [],
      error: null,
    };

    setAllComments({
      ...allComments,
      commentsByPostId: {
        ...allComments.commentsByPostId,
        ...commentsForPost,
      },
    });
  };

  const fetchCommentsReject = (postId, error) => {
    const commentsForPost = {};
    commentsForPost[postId] = {
      isFetched: false,
      isFetching: false,
      error: error,
    };
    setAllComments({
      ...allComments,
      commentsByPostId: {
        ...allComments.commentsByPostId,
        ...commentsForPost,
      },
    });
  };

  return (
    <MainContext.Provider
      value={{
        ...data,
        boards: boardsData.boards
          .map(board => new Board(board))
          .filter(board => board.writableByUser),
        defaultBoard,
        posts,
        postCategories,
        postFetching,
        fetchCommentsForPost,
        commentsForPost,
        fetchError,
        createError,
        createErrorConfirmed,
        setCreateErrorConfirmed,
        setContextData: setData,
      }}>
      {showUpdate && <UpdateApp cancelClick={() => setShowUpdate(false)} />}
      {children}
    </MainContext.Provider>
  );
};
