var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HideShow from '../HideShow/HideShow';
import UIListItem from '../UIListItem/UIListItem';
import s from '../RemoveCommentListItem/RemoveCommentListItem.module.scss';
import { useMessageRemove } from '../../hooks/messageHooks';
import { useTranslation } from 'react-i18next';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import Button from '../Button/Button';
/**
 *
 * @param {RemoveMessageListItemProps} props
 * @returns {JSX.Element}
 */
var RemoveMessageListItem = function (_a) {
    var messageId = _a.messageId, chatId = _a.chatId, toggleActionSheet = _a.toggleActionSheet;
    var _b = useMessageRemove(chatId, messageId), removeMessage = _b.removeMessage, removed = _b.removed, isRemoving = _b.isRemoving;
    var t = useTranslation().t;
    var removeStatus = removed;
    var _c = useState(false), showDecision = _c[0], setShowDecision = _c[1];
    var _d = useState(t('addSavedList.label')), label = _d[0], setLabel = _d[1];
    useEffect(function () {
        if (isRemoving) {
            setLabel(t('removingMessage.label'));
        }
        else if (removed) {
            setLabel(t('messageRemoved.label'));
        }
        else {
            setLabel(t('removeComment.label'));
        }
    }, [isRemoving, removeStatus, removed, t]);
    var iconStyles = [s.icon, isRemoving && s.saving].join(' ');
    var icon = isRemoving ? (_jsx(FontAwesomeIcon, { className: iconStyles, icon: ['far', 'hourglass'] })) : (_jsx(FontAwesomeIcon, { className: iconStyles, icon: ['fas', 'trash'] }));
    var handleRemove = function () {
        void removeMessage();
        toggleActionSheet(null);
    };
    return (_jsxs(_Fragment, { children: [_jsx(UIListItem, __assign({ icon: icon, disabled: isRemoving, onClick: function () { return setShowDecision(true); } }, { children: label })), _jsxs(HideShow, __assign({ show: showDecision }, { children: [_jsx("div", __assign({ className: s.decisionCopy }, { children: _jsx("p", { children: t('sureRemove.label') }) })), _jsxs(ButtonGroup, __assign({ borderTop: true }, { children: [_jsx(Button, { label: t('no.label'), type: "button", onClick: function () { return setShowDecision(false); } }), _jsx(Button, { label: t('yes.label'), type: "button", onClick: function () { return handleRemove(); } })] }))] }))] }));
};
export default RemoveMessageListItem;
