var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import _ from 'lodash';
import CheckboxButtonGroup from '../components/CheckboxButtonGroup/CheckboxButtonGroup';
import CommentField from '../components/CommentField/CommentField';
import TextField from '../components/TextField/TextField';
import TextFieldSlim from '../components/TextFieldSlim/TextFieldSlim';
import RadioGroup from '../components/RadioGroup/RadioGroup';
import SelectField from '../components/SelectField/SelectField';
import DropZoneField from '../components/DropZoneField/DropZoneField';
import DropZoneVideoField from '../components/DropZoneVideoField/DropZoneVideoField';
import EditorField from '../components/EditorField/EditorField';
export var renderCheckboxButtonGroup = function (_a) {
    var _b;
    var field = _a.field, form = _a.form, label = _a.label, children = _a.children, custom = __rest(_a, ["field", "form", "label", "children"]);
    return (_jsx(CheckboxButtonGroup, __assign({ label: label, value: field.value, touched: !!_.get(form.touched, field.name), errorText: (_b = _.get(form.errors, field.name)) === null || _b === void 0 ? void 0 : _b.toString(), onChange: function (newVal) { return form.setFieldValue(field.name, newVal, true); }, onBlur: function () { return form.setFieldTouched(field.name, true, true); } }, custom)));
};
export var renderDropZoneField = function (_a) {
    var field = _a.field, form = _a.form, label = _a.label, children = _a.children, custom = __rest(_a, ["field", "form", "label", "children"]);
    return (_jsx(DropZoneField, __assign({ name: field.name, label: label, value: field.value, touched: !!_.get(form.touched, field.name), errorText: _.get(form.errors, field.name), onChange: function (newVal) { return form.setFieldValue(field.name, newVal, true); } }, custom)));
};
export var renderDropZoneVideoField = function (_a) {
    var _b;
    var field = _a.field, form = _a.form, label = _a.label, children = _a.children, custom = __rest(_a, ["field", "form", "label", "children"]);
    return (_jsx(DropZoneVideoField, __assign({ name: field.name, label: label, value: field.value, touched: !!_.get(form.touched, field.name), errorText: (_b = _.get(form.errors, field.name)) === null || _b === void 0 ? void 0 : _b.toString(), onChange: function (newVal) { return form.setFieldValue(field.name, newVal, true); } }, custom)));
};
export var renderEditorField = function (_a) {
    var _b;
    var field = _a.field, form = _a.form, label = _a.label, children = _a.children, custom = __rest(_a, ["field", "form", "label", "children"]);
    return (_jsx(EditorField, __assign({ name: field.name, label: label, value: field.value, touched: !!_.get(form.touched, field.name), errorText: (_b = _.get(form.errors, field.name)) === null || _b === void 0 ? void 0 : _b.toString(), onChange: function (newVal) { return form.setFieldValue(field.name, newVal, true); }, onBlur: function () { return form.setFieldTouched(field.name, true, true); } }, custom)));
};
export var renderCommentField = function (_a) {
    var field = _a.field, form = _a.form, custom = __rest(_a, ["field", "form"]);
    return (_jsx(CommentField, __assign({ name: field.name, value: field.value, onChange: field.onChange, onBlur: field.onBlur }, custom)));
};
export var renderTextField = function (_a) {
    var _b;
    var field = _a.field, form = _a.form, label = _a.label, custom = __rest(_a, ["field", "form", "label"]);
    return (_jsx(TextField, __assign({ name: field.name, label: label, value: field.value, touched: !!_.get(form.touched, field.name), errorText: (_b = _.get(form.errors, field.name)) === null || _b === void 0 ? void 0 : _b.toString(), onChange: field.onChange, onBlur: field.onBlur }, custom)));
};
export var renderTextFieldSlim = function (_a) {
    var _b;
    var field = _a.field, form = _a.form, label = _a.label, custom = __rest(_a, ["field", "form", "label"]);
    return (_jsx(TextFieldSlim, __assign({ name: field.name, label: label, value: field.value, touched: !!_.get(form.touched, field.name), errorText: (_b = _.get(form.errors, field.name)) === null || _b === void 0 ? void 0 : _b.toString(), onChange: field.onChange, onBlur: field.onBlur }, custom)));
};
export var renderSelectField = function (_a) {
    var _b;
    var field = _a.field, form = _a.form, label = _a.label, custom = __rest(_a, ["field", "form", "label"]);
    return (_jsx(SelectField, __assign({ name: field.name, label: label, value: field.value, touched: !!_.get(form.touched, field.name), errorText: (_b = _.get(form.errors, field.name)) === null || _b === void 0 ? void 0 : _b.toString(), onChange: function (newVal) { return form.setFieldValue(field.name, newVal, true); }, onBlur: field.onBlur }, custom)));
};
export var renderRadioGroup = function (_a) {
    var _b;
    var field = _a.field, form = _a.form, label = _a.label, custom = __rest(_a, ["field", "form", "label"]);
    return (_jsx(RadioGroup, __assign({ name: field.name, label: label, value: field.value, touched: !!_.get(form.touched, field.name), errorText: (_b = _.get(form.errors, field.name)) === null || _b === void 0 ? void 0 : _b.toString(), onChange: field.onChange }, custom)));
};
