import PropTypes from 'prop-types';
import { imageType } from './image';

export const authorType = {
  uuid: PropTypes.number,
  id: PropTypes.number,
  username: PropTypes.string,
  avatar: PropTypes.object,
};

export const authorTypeDefault = {
  avatar: null,
};
