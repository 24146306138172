var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { updateLanguagesDispatch, updateLanguagesFulfill, updateLanguagesReject, } from '../../redux/modules/autoTranslate';
import { useEffect, useState } from 'react';
import Button from '../Button/Button';
import Card from '../Card/Card';
import Modal from '../Modal/Modal';
import { Preferences } from '@capacitor/preferences';
import Toggle from '../Toggle/Toggle';
import api from '../../util/api';
import s from './AutoTranslateDisclaimer.module.scss';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
/**
 * The component is a disclaimer popup that users see upon their first access to the site from a new device.
 * It highlights the basic terms of use for users.
 * The AutoTranslater Disclaimer label is the latest disclaimer set by the client.
 * @returns {JSX.Element}
 */
var AutoTranslateDisclaimer = function () {
    var dispatch = useDispatch();
    var _a = useState(false), showDisclaimer = _a[0], setShowDisclaimer = _a[1];
    var _b = useTranslation(), t = _b.t, i18n = _b.i18n;
    useEffect(function () {
        //check if autoTranslateDisclaimerShown is set
        void Preferences.get({
            key: 'autoTranslateDisclaimerShown',
        }).then(function (_a) {
            var value = _a.value;
            if (value === null) {
                setShowDisclaimer(true);
            }
            else if (value !== 'true') {
                setShowDisclaimer(false);
                return;
            }
        });
        void Preferences.get({
            key: 'autoTranslateStatus',
        }).then(function (_a) {
            var value = _a.value;
            if (!value) {
                void Preferences.set({
                    key: 'autoTranslateStatus',
                    value: 'true',
                });
            }
        });
    }, []);
    var _c = useState(false), isChanging = _c[0], setIsChanging = _c[1];
    var _d = useState(true), autoTranslateStatus = _d[0], setAutoTranslateStatus = _d[1];
    var toggleAutoTranslate = function () {
        setAutoTranslateStatus(!autoTranslateStatus);
    };
    return (_jsx(Modal, __assign({ show: showDisclaimer }, { children: _jsx(Card, __assign({ title: t('autoTranslate.label'), className: s.wrapper }, { children: _jsxs("div", __assign({ className: s.cardcontent }, { children: [_jsxs("div", __assign({ className: s.checkbox }, { children: [_jsx("div", __assign({ className: s.textbody }, { children: t('autoTranslatePrompt.label') })), ' ', _jsx(Toggle, { id: '1', checked: autoTranslateStatus, pending: isChanging, onChange: toggleAutoTranslate, className: undefined, onClick: undefined })] })), _jsx(Button, { label: t('save.label'), onClick: function () {
                            return void Preferences.set({
                                key: 'autoTranslateDisclaimerShown',
                                value: '1',
                            }).then(function () {
                                setShowDisclaimer(false);
                                var autoTranslatePreference = autoTranslateStatus ? true : false;
                                var values = {
                                    language: i18n.language,
                                    autoTranslatePreference: autoTranslatePreference,
                                };
                                dispatch(updateLanguagesDispatch(values));
                                api
                                    .autoTranslate(values)
                                    .then(function (response) {
                                    dispatch(updateLanguagesFulfill(response));
                                })
                                    .catch(function (error) {
                                    dispatch(updateLanguagesReject(error.message));
                                });
                                setIsChanging(true);
                                setAutoTranslateStatus(!autoTranslateStatus);
                                setIsChanging(false);
                            });
                        }, fullWidth: true })] })) })) })));
};
export default AutoTranslateDisclaimer;
