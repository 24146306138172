import { combineEpics } from 'redux-observable';
import { of, from } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import api from '../../util/api';
import {
  SAVE_DISPATCH,
  saveReject,
  saveFulfill,
  UNSAVE_DISPATCH,
  unSaveReject,
  unSaveFulfill,
} from '../modules/saved';


export const saveEpic = action$ => action$.ofType(SAVE_DISPATCH)
  .pipe(mergeMap(action => from(api.savePost(action.postId))
    .pipe(
      map(savedPosts => saveFulfill(savedPosts)),
      catchError(error => of(
        saveReject(error),
      )),
    )));

export const unSaveEpic = action$ => action$.ofType(UNSAVE_DISPATCH)
  .pipe(mergeMap(action => from(api.unSavePost(action.postId))
    .pipe(
      map(savedPosts => unSaveFulfill(savedPosts)),
      catchError(error => of(
        unSaveReject(error),
      )),
    )));


export default combineEpics(
  saveEpic,
  unSaveEpic,
);
