var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var SAVE_DISPATCH = 'WIS/save/SAVE_DISPATCHED';
export var SAVE_FULFILL = 'WIS/save/SAVE_FULFILLED';
export var SAVE_REJECT = 'WIS/save/SAVE_REJECTED';
export var UNSAVE_DISPATCH = 'WIS/save/UNSAVE_DISPATCHED';
export var UNSAVE_FULFILL = 'WIS/save/UNSAVE_FULFILLED';
export var UNSAVE_REJECT = 'WIS/save/UNSAVE_REJECTED';
export var save = function (postId) { return ({ type: SAVE_DISPATCH, postId: postId }); };
export var saveFulfill = function (post) { return ({ type: SAVE_FULFILL, post: post }); };
export var saveReject = function (error) { return ({ type: SAVE_REJECT, error: error }); };
export var unSave = function (postId) { return ({ type: UNSAVE_DISPATCH, postId: postId }); };
export var unSaveFulfill = function (post) { return ({ type: UNSAVE_FULFILL, post: post }); };
export var unSaveReject = function (error) { return ({ type: UNSAVE_REJECT, error: error }); };
var initialState = {
    posts: ['P000002', 'P000004', 'P000006'],
    updating: false,
    error: null,
};
export default function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case SAVE_DISPATCH:
            return __assign(__assign({}, state), { updating: true, error: null });
        case SAVE_FULFILL:
            return __assign(__assign({}, state), { updating: false, posts: __spreadArray(__spreadArray([], state.posts, true), [action.post], false), error: null });
        case SAVE_REJECT:
            return __assign(__assign({}, state), { updating: false, error: action.error });
        case UNSAVE_DISPATCH:
            return __assign(__assign({}, state), { updating: true, error: null });
        case UNSAVE_FULFILL:
            return __assign(__assign({}, state), { updating: false, posts: __spreadArray(__spreadArray([], state.posts, true), [action.post], false), error: null });
        case UNSAVE_REJECT:
            return __assign(__assign({}, state), { updating: false, error: action.error });
        default:
            return state;
    }
}
