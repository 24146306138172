var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var BOARD_GET_ALL_DISPATCH = 'WIS/boards/BOARD_GET_ALL_DISPATCHED';
export var BOARD_GET_ALL_FULFILL = 'WIS/boards/BOARD_GET_ALL_FULFILLED';
export var BOARD_GET_ALL_REJECT = 'WIS/boards/BOARD_GET_ALL_REJECTED';
export var boardGetAllDispatch = function () { return ({ type: BOARD_GET_ALL_DISPATCH }); };
export var boardGetAllFulfill = function (boards) { return ({ type: BOARD_GET_ALL_FULFILL, boards: boards }); };
export var boardGetAllReject = function (error) { return ({ type: BOARD_GET_ALL_REJECT, error: error }); };
var initialState = {
    boards: [],
    isFetching: false,
    isFetched: false,
    lastFetched: null,
    fetchError: null,
};
export default function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case BOARD_GET_ALL_DISPATCH:
            return __assign(__assign({}, state), { isFetching: true, isFetched: false, fetchError: null });
        case BOARD_GET_ALL_FULFILL:
            return __assign(__assign({}, state), { boards: action.boards, isFetching: false, isFetched: true, fetchError: null });
        case BOARD_GET_ALL_REJECT:
            return __assign(__assign({}, state), { isFetching: false, isFetched: false, fetchError: action.error });
        default:
            return state;
    }
}
