var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var INITIAL_LIKES = 'WIS/like/INITIAL_LIKES';
export var LIKE_DISPATCH = 'WIS/like/LIKE_DISPATCHED';
export var LIKE_FULFILL = 'WIS/like/LIKE_FULFILLED';
export var LIKE_REJECT = 'WIS/like/LIKE_REJECTED';
export var UNLIKE_DISPATCH = 'WIS/like/UNLIKE_DISPATCHED';
export var UNLIKE_FULFILL = 'WIS/like/UNLIKE_FULFILLED';
export var UNLIKE_REJECT = 'WIS/like/UNLIKE_REJECTED';
export var initialLikes = function (posts) { return ({ type: INITIAL_LIKES, posts: posts }); };
export var like = function (postId) { return ({ type: LIKE_DISPATCH, postId: postId }); };
export var likeFulfill = function (post) { return ({ type: LIKE_FULFILL, post: post }); };
export var likeReject = function (error) { return ({ type: LIKE_REJECT, error: error }); };
export var unLike = function (postId) { return ({ type: UNLIKE_DISPATCH, postId: postId }); };
export var unLikeFulfill = function (post) { return ({ type: UNLIKE_FULFILL, post: post }); };
export var unLikeReject = function (error) { return ({ type: UNLIKE_REJECT, error: error }); };
var initialState = {
    posts: ['P000002', 'P000004', 'P000006'],
    updating: false,
    error: null,
};
export default function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case INITIAL_LIKES:
            return __assign(__assign({}, state), { posts: action.posts, updating: false, error: null });
        case LIKE_DISPATCH:
            return __assign(__assign({}, state), { updating: true, error: null });
        case LIKE_FULFILL:
            return __assign(__assign({}, state), { updating: false, posts: __spreadArray(__spreadArray([], state.posts, true), [action.post], false), error: null });
        case LIKE_REJECT:
            return __assign(__assign({}, state), { updating: false, error: action.error });
        case UNLIKE_DISPATCH:
            return __assign(__assign({}, state), { updating: true, error: null });
        case UNLIKE_FULFILL:
            return __assign(__assign({}, state), { updating: false, posts: state.posts.filter(function (elem) { return elem !== action.post; }), error: null });
        case UNLIKE_REJECT:
            return __assign(__assign({}, state), { updating: false, error: action.error });
        default:
            return state;
    }
}
