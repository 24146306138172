var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var GET_POSTS_DISPATCH = 'WIS/posts/GET_POSTS_DISPATCHED';
export var GET_POSTS_DISPATCH_FILTERED = 'WIS/posts/GET_POSTS_DISPATCHED_FILTERED';
export var GET_POSTS_FULFILL = 'WIS/posts/GET_POSTS_FULFILLED';
export var GET_POSTS_REJECT = 'WIS/posts/GET_POSTS_REJECTED';
export var GET_POSTS_GLOBAL_DISPATCH = 'WIS/posts/GET_POSTS_GLOBAL_DISPATCHED';
export var POST_CREATE_DISPATCH = 'WIS/posts/POST_CREATE_DISPATCHED';
export var POST_CREATE_FULFILL = 'WIS/posts/POST_CREATE_FULFILLED';
export var POST_CREATE_REJECT = 'WIS/posts/POST_CREATE_REJECTED';
export var POST_UNPUBLISH_DISPATCH = 'WIS/posts/POST_UNPUBLISH_DISPATCHED';
export var POST_UNPUBLISH_FULFILL = 'WIS/posts/POST_UNPUBLISH_FULFILLED';
export var POST_UNPUBLISH_REJECT = 'WIS/posts/POST_UNPUBLISH_REJECTED';
export var getPostsDispatch = function (boardId, uid, keywords, offset, limit, isFullRefresh, categoryId) { return ({
    type: GET_POSTS_DISPATCH,
    boardId: boardId,
    uid: uid,
    keywords: keywords,
    offset: offset,
    limit: limit,
    isFullRefresh: isFullRefresh,
    categoryId: categoryId,
}); };
export var getPostsDispatchFiltered = function (boardId, uid, keywords) { return ({
    type: GET_POSTS_DISPATCH_FILTERED,
    boardId: boardId,
    uid: uid,
    keywords: keywords,
}); };
export var getPostsFulfill = function (posts, uid, isFullRefresh) { return ({
    type: GET_POSTS_FULFILL,
    posts: posts,
    uid: uid,
    isFullRefresh: isFullRefresh,
}); };
export var getPostsReject = function (error) { return ({
    type: GET_POSTS_REJECT,
    error: error,
}); };
export var getPostsGlobalDispatch = function (boardId) { return ({
    type: GET_POSTS_GLOBAL_DISPATCH,
    boardId: boardId,
}); };
export var postCreateDispatch = function (values) { return ({
    type: POST_CREATE_DISPATCH,
    values: values,
}); };
export var postCreateFulfill = function (post) { return ({
    type: POST_CREATE_FULFILL,
    post: post,
}); };
export var postCreateReject = function (error) { return ({
    type: POST_CREATE_REJECT,
    error: error,
}); };
export var postUnpublishDispatch = function (postId) { return ({
    type: POST_UNPUBLISH_DISPATCH,
    postId: postId,
}); };
export var postUnpublishFulfill = function (post) { return ({
    type: POST_UNPUBLISH_FULFILL,
    post: post,
}); };
export var postUnpublishReject = function (error) { return ({
    type: POST_UNPUBLISH_REJECT,
    error: error,
}); };
var initialState = {
    posts: [],
    isFetching: false,
    isFetched: false,
    lastFetched: null,
    isPosting: false,
    isPosted: false,
    unpublishedSinceLastFetched: [],
    isUnpublishing: false,
    unPublishingId: null,
    unPublishError: null,
    fetchError: null,
    createError: null,
};
var removePostFromPosts = function (postId, posts) {
    var newPostsState = __spreadArray([], posts, true);
    var postIndex = newPostsState.findIndex(function (post) { return post.id === postId; });
    newPostsState.splice(postIndex, 1);
    return newPostsState;
};
export default function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case POST_CREATE_DISPATCH:
            return __assign(__assign({}, state), { isPosting: true, isPosted: false, createError: null });
        case POST_CREATE_FULFILL:
            return __assign(__assign({}, state), { isPosting: false, isPosted: true, createError: null });
        case POST_CREATE_REJECT:
            return __assign(__assign({}, state), { isPosting: false, isPosted: false, createError: action.error });
        case GET_POSTS_DISPATCH:
            if (action.isFullRefresh) {
                return __assign(__assign({}, state), { posts: [], isFetching: true, isFetched: false, fetchError: null });
            }
            return __assign(__assign({}, state), { isFetching: true, isFetched: false, fetchError: null });
        case GET_POSTS_DISPATCH_FILTERED:
            var filteredPosts = state.posts.filter(function (post) {
                return post.author.username
                    .toLowerCase()
                    .includes(action.keywords.toLowerCase()) ||
                    post.title.toLowerCase().includes(action.keywords.toLowerCase()) ||
                    post.content.toLowerCase().includes(action.keywords.toLowerCase());
            });
            return __assign(__assign({}, state), { isFetching: false, isFetched: true, posts: filteredPosts || state.posts, lastFetched: Date.now(), unpublishedSinceLastFetched: [], fetchError: null });
        case GET_POSTS_GLOBAL_DISPATCH:
            return __assign(__assign({}, state), { isFetching: true, isFetched: false, fetchError: null });
        case GET_POSTS_FULFILL:
            console.log('action.isFullRefresh', action.isFullRefresh);
            var newPosts = action.posts;
            if (action.uid) {
                // Filter posts based on uid
                newPosts = newPosts.filter(function (post) { return post.author.uuid === action.uid; });
            }
            // Determine if this is a full refresh or infinite scroll update
            var isFullRefresh = action.isFullRefresh || false;
            // For full refresh, replace the posts; for infinite scroll, concatenate new posts
            var updatedPosts = isFullRefresh
                ? newPosts
                : state.posts.concat(newPosts.filter(function (newPost) {
                    return !state.posts.some(function (existingPost) { return existingPost.id === newPost.id; });
                }));
            return __assign(__assign({}, state), { isFetching: false, isFetched: true, posts: updatedPosts, lastFetched: Date.now(), unpublishedSinceLastFetched: [], fetchError: null });
        case GET_POSTS_REJECT:
            return __assign(__assign({}, state), { isFetching: false, isFetched: false, fetchError: action.error });
        case POST_UNPUBLISH_DISPATCH:
            return __assign(__assign({}, state), { isUnpublishing: true, unPublishingId: action.postId, unPublishError: null });
        case POST_UNPUBLISH_FULFILL:
            return __assign(__assign({}, state), { posts: removePostFromPosts(action.post.id, state.posts), isUnpublishing: false, unpublishedSinceLastFetched: __spreadArray(__spreadArray([], state.unpublishedSinceLastFetched, true), [
                    action.post.id,
                ], false), unPublishingId: null, unPublishError: null });
        case POST_UNPUBLISH_REJECT:
            return __assign(__assign({}, state), { isUnpublishing: false, unPublishingId: null, unPublishError: action.error });
        default:
            return state;
    }
}
