import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import { isNative } from 'lodash';
export var logAnalitycs = function (event, eventProps) {
    if (isNative) {
        void FirebaseAnalytics.logEvent({
            name: event,
            params: eventProps,
        });
    }
    else {
        var analytics = getAnalytics();
        logEvent(analytics, event, eventProps);
    }
};
export var useAnalitycs = function () {
    if (isNative) {
        return {
            logEvent: logAnalitycs,
            setUserId: function (userId) { return FirebaseAnalytics.setUserId({ userId: userId }); },
            setUserProperties: function (props) {
                void FirebaseAnalytics.setUserProperty(props);
            },
        };
    }
    return {
        logEvent: logAnalitycs,
        setUserId: setUserId,
        setUserProperties: setUserProperties,
    };
};
