import { useState, useEffect, useRef } from 'react';
import api from '../util/api';
export var useMessageRemove = function (chatId, messageId) {
    var _a = useState(false), removed = _a[0], setRemoved = _a[1];
    var _b = useState(false), isRemoving = _b[0], setIsRemoving = _b[1];
    var _c = useState(), error = _c[0], setError = _c[1];
    var isMounted = useRef(true);
    useEffect(function () {
        return function () {
            isMounted.current = false;
        };
    }, []);
    var removeMessage = function () {
        setIsRemoving(true);
        return api
            .deleteChatMessage(chatId, messageId)
            .then(function () {
            if (isMounted.current) {
                setRemoved(true);
                document.getElementById(String(messageId)).remove();
            }
        })
            .catch(function (e) {
            console.error(e.message);
            if (isMounted.current)
                setError(e.message);
        })
            .finally(function () {
            if (isMounted.current)
                setIsRemoving(false);
        });
    };
    return { removed: removed, isRemoving: isRemoving, removeMessage: removeMessage, error: error };
};
export var useMessageLiked = function (chatId, message_id) {
    var _a = useState(false), liked = _a[0], setLiked = _a[1];
    var _b = useState(false), isLiking = _b[0], setIsLiking = _b[1];
    var _c = useState(), error = _c[0], setError = _c[1];
    var isMounted = useRef(true);
    useEffect(function () {
        return function () {
            isMounted.current = false;
        };
    }, []);
    var removeLikeMessage = function () {
        setIsLiking(true);
        return api
            .removeLikeChatMessage(chatId, message_id)
            .then(function () {
            if (isMounted.current)
                setLiked(false);
        })
            .catch(function (e) {
            console.error(e.message);
            if (isMounted.current)
                setError(e.message);
        })
            .finally(function () {
            if (isMounted.current)
                setIsLiking(false);
        });
    };
    var likeMessage = function () {
        setIsLiking(true);
        return api
            .likeChatMessage(chatId, message_id)
            .then(function () {
            if (isMounted.current)
                setLiked(true);
        })
            .catch(function (e) {
            console.error(e.message);
            if (isMounted.current)
                setError(e.message);
        })
            .finally(function () {
            if (isMounted.current)
                setIsLiking(false);
        });
    };
    return { liked: liked, likeMessage: likeMessage, removeLikeMessage: removeLikeMessage, setLiked: setLiked, error: error };
};
export var useMessageHide = function (chatId, messageId) {
    var _a = useState(false), hidden = _a[0], setHidden = _a[1];
    var _b = useState(false), isHiding = _b[0], setIsHiding = _b[1];
    var _c = useState(), error = _c[0], setError = _c[1];
    var isMounted = useRef(true);
    useEffect(function () {
        return function () {
            isMounted.current = false;
        };
    }, []);
    var hideMessage = function (hide) {
        setIsHiding(true);
        return api
            .hideChatMessage(chatId, messageId, hide)
            .then(function () {
            if (isMounted.current)
                setHidden(true);
        })
            .catch(function (e) {
            console.error(e.message);
            if (isMounted.current)
                setError(e.message);
        })
            .finally(function () {
            if (isMounted.current)
                setIsHiding(false);
        });
    };
    var getOriginalContent = function () { return api.getHiddenChatMessage(chatId, messageId); };
    return { hidden: hidden, isHiding: isHiding, hideMessage: hideMessage, error: error, getOriginalContent: getOriginalContent };
};
