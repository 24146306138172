import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { block as blockDispatch, unBlock as unBlockDispatch } from '../redux/modules/blocked';
import { useProfile } from '../util/APIDjango';
import api from '../util/api';
import { useProfileData } from './useProfileData';

// const mapStateToProps = ({ blocked }) => ({
//   blockedUsers: blocked.users,
//   isChanging: blocked.updating,
// });

function useUserBlock(uuid) {
  const dispatch = useDispatch();
  // const { blockedUsers, isChanging } = useSelector(mapStateToProps);
  const { profileData, setProfileData } = useProfileData();
  const [isBlocked, setIsBlocked] = useState(false);
  const [optimisticBlocked, setOptimisticBlocked] = useState(false);
  // stop all consumers being affected by isChanging
  const [canChange, setCanChange] = useState(false);

  const blockDispatch = () => setProfileData({ ...profileData, isUpdating: true });

  const blockFulfill = profileData =>
    setProfileData({
      ...profileData,
      blockedUsers: profileData,
      isUpdating: false,
      error: null,
    });

  const blockReject = error => setProfileData({ ...profileData, error: error, isUpdating: false });

  const unBlockDispatch = () => setProfileData({ ...profileData, isUpdating: true });

  const unBlockFulfill = profileData =>
    setProfileData({
      ...profileData,
      blockedUsers: profileData,
      isUpdating: false,
      error: null,
    });

  const unBlockReject = error =>
    setProfileData({ ...profileData, error: error, isUpdating: false });

  useEffect(() => {
    // if this stops firing check that a new array is being returned rather than a modified original
    setIsBlocked(profileData.blockedUsers?.some(user => user.uuid === uuid));
    setCanChange(false);
  }, [uuid, profileData.blockedUsers]);

  const block = () => {
    setOptimisticBlocked(true);
    // dispatch(blockDispatch(uuid));
    blockDispatch();
    api
      .blockUser(uuid)
      .then(blockedData => {
        blockFulfill(blockedData);
      })
      .catch(err => {
        blockReject(err.message);
      });
  };

  const unBlock = () => {
    setOptimisticBlocked(false);
    // dispatch(unBlockDispatch(uuid));
    unBlockDispatch();
    api
      .unblockUser(uuid)
      .then(blockedData => {
        unBlockFulfill(blockedData);
      })
      .catch(err => {
        unBlockReject(err.message);
      });
  };

  const toggleBlocked = () => {
    setCanChange(true);
    if (isBlocked) {
      unBlock();
    } else {
      block();
    }
  };

  return {
    toggleBlocked,
    isBlocked,
    isChanging: canChange && profileData.isUpdating,
    optimisticBlocked,
  };
}

export default useUserBlock;
