import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useUserBlock from '../../hooks/useUserBlock';
import UIListItem from '../UIListItem/UIListItem';
import Toggle from '../Toggle/Toggle';
import Avatar from '../Avatar/Avatar';
import { authorType } from '../../proptypes/author';
import styles from './UIBlockListItem.module.scss';
import defaultAvatar from '../../assets/images/default-avatar.svg';

const UIBlockListItem = ({ author, id, className }) => {
  const { toggleBlocked, isBlocked, isChanging, optimisticBlocked } = useUserBlock(
    author?.id || author?.uuid,
  );

  const blockStatus = isChanging ? optimisticBlocked : isBlocked;

  return (
    <UIListItem className={className} onKeyUp={e => (e.keyCode === 13 ? toggleBlocked() : null)}>
      <div className={[styles.main, author?.avatar && styles.hasAvatar].join(' ')}>
        {author?.avatar && (
          <Avatar
            className={styles.avatar}
            src={author?.avatar?.src || defaultAvatar}
            alt={author?.username}
          />
        )}
        <div className={styles.content}>{author?.username}</div>
        <Toggle
          className={styles.toggle}
          id={id}
          checked={blockStatus}
          pending={isChanging}
          onChange={toggleBlocked}
        />
      </div>
    </UIListItem>
  );
};

UIBlockListItem.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  author: PropTypes.shape(authorType).isRequired,
};

export default UIBlockListItem;
