import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal/Modal';
import Card from '../Card/Card';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import Button from '../Button/Button';

// no hook for componentDidCatch yet (05/09/2019) so need to use a class component
export default class AppErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  render() {
    const refreshApp = () => {
      window.location = '';
    };
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? <ErrorModal refreshApp={refreshApp} /> : children;
  }
}

const ErrorModal = ({ refreshApp }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Modal show>
        <Card title={t('somethingWrong.label')}>
          <p>
            {t('apologiesReload.label')}
          </p>
          <ButtonGroup>
            <Button label="Reload" fullWidth onClick={refreshApp} />
          </ButtonGroup>
        </Card>
      </Modal>
    </div>
  );
};
