var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var GET_POST_CATEGORIES_DISPATCH = 'WIS/posts/GET_POST_CATEGORIES_DISPATCH';
export var GET_POST_CATEGORIES_FULFILL = 'WIS/posts/GET_POST_CATEGORIES_FULFILLED';
export var GET_POST_CATEGORIES_REJECT = 'WIS/posts/GET_POST_CATEGORIES_REJECTED';
export var getPostCategoriesDispatch = function () { return ({
    type: GET_POST_CATEGORIES_DISPATCH,
}); };
export var getPostCategoriesFulfill = function (categories) { return ({
    type: GET_POST_CATEGORIES_FULFILL,
    categories: categories,
}); };
export var getPostCategoriesReject = function (error) { return ({
    type: GET_POST_CATEGORIES_REJECT,
    error: error,
}); };
var initialState = {
    categories: [],
    isFetching: false,
    isFetched: false,
    fetchError: null,
};
export default function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case GET_POST_CATEGORIES_DISPATCH:
            return __assign(__assign({}, state), { isFetching: true, isFetched: false, fetchError: '' });
        case GET_POST_CATEGORIES_FULFILL:
            return __assign(__assign({}, state), { isFetching: false, isFetched: true, categories: action.categories, fetchError: '' });
        case GET_POST_CATEGORIES_REJECT:
            return __assign(__assign({}, state), { isFetching: false, isFetched: false, fetchError: action.error });
        default:
            return state;
    }
}
