import React, { useEffect } from 'react';
import { TransitionMotion, spring } from 'react-motion';

import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import styles from './FullModal.module.scss';
import useModal from '../../hooks/useModal';

const FullModal = ({ show, className, children, backDropClick, isChatPage = false }) => {
  const setShow = useModal();
  useEffect(() => {
    setShow(show);
  }, [show]);

  useEffect(() => {
    document.getElementById('app')?.classList.add('fullscreen');
    return () => document.getElementById('app')?.classList.remove('fullscreen');
  }, []);
  const modalRoot = document.getElementById('modal-root');
  const springConfig = { stiffness: 140, damping: 16 };
  const willLeave = () => ({
    opacity: spring(0, springConfig),
    y: spring(-200, springConfig),
    size: spring(0, springConfig),
  });
  const willEnter = () => ({
    opacity: 0,
    y: -400,
    size: 0,
  });

  return ReactDOM.createPortal(
    <TransitionMotion
      willEnter={willEnter}
      styles={
        show
          ? [
              {
                key: 'a',
                style: {
                  opacity: spring(1, springConfig),
                  y: spring(0, springConfig),
                  size: spring(100, springConfig),
                },
              },
            ]
          : []
      }>
      {items => {
        if (items.length) {
          const { key, style } = items[0];
          return (
            <div
              key={key}
              className={styles.backdrop}
              style={{ opacity: style.opacity }}
              onClick={backDropClick}>
              <div
                className={[styles.modal, isChatPage && styles.chat, className].join(' ')}
                style={{
                  position: 'relative',
                  bottom: style.y,
                  opacity: style.opacity,
                  width: `${style.size}%`,
                  height: `${style.size}%`,
                }}
                onClick={e => e.stopPropagation()}>
                {children}
              </div>
            </div>
          );
        }
        return null;
      }}
    </TransitionMotion>,
    modalRoot,
  );
};

FullModal.propTypes = {
  show: PropTypes.bool,
  backDropClick: PropTypes.func,
};

FullModal.defaultProps = {
  show: false,
  backDropClick: null,
};

export default FullModal;
