import React from 'react';
import PropTypes from 'prop-types';
import styles from './Loader.module.scss';
import clsx from 'clsx';

const Loader = ({ label, fullPage = false, title = '' }) => {
  const firstLoaderClasses = clsx(styles.loader1, styles.loader);
  const subsequentLoaderClasses = [styles.loader2, styles.loader].join(' ');

  return (
    <div className={clsx(styles.wrapper, fullPage && styles.fullPage)}>
      <div className={styles.loaderWrapper}>
        <div className={firstLoaderClasses} />
        <div className={subsequentLoaderClasses} />
        <div className={subsequentLoaderClasses} />
      </div>
      {title && <div className={styles.title}>{title}</div>}
      {label && <div className={styles.label}>{label}</div>}
    </div>
  );
};

Loader.propTypes = {
  label: PropTypes.string,
};

Loader.defaultProps = {
  label: null,
};

export default Loader;
