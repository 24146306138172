export default class Board {
  constructor(board) {
    this.boardId = board.boardId;
    this.name = board.name;
    this.image = board.image;
    this.created = new Date(board.created);
    this.description = board.description;
    this.country = board.country;
    this.isDefaultForUser = board.isDefaultForUser;
    this.writableByUser = board.writableByUser;
    this.isGlobal = board.isGlobal;
    this.lastModified = new Date(board.lastModified);
    this.lastPost = new Date(board.lastPost);
    this.recentPostCount = board.recentPostCount;
  }
}
