import { FEATURE_FLAGS } from '../../util/config';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import React from 'react';
import logo from '../../assets/images/footer-logo.png';
import styles from './Footer.module.scss';
import { useTranslation } from 'react-i18next';
import { Facebook } from '../Icons/Facebook';
import { X } from '../Icons/X';
import { Instagram } from '../Icons/Instagram';
import { Youtube } from '../Icons/Youtube';
import { AppStore } from '../Icons/AppStor';
import { PlayStore } from '../Icons/PlayStore';

const Footer = ({ className }) => {
  const { t } = useTranslation();

  return (
    <footer className={[styles.wrapper, className].join(' ')}>
      <div className={styles.topContent}>
        <div className={styles.logo}>
          <img src={logo} alt="Invictus Games" />
        </div>
        <div className={styles.moto}>
          {t('moto1.label')}
          <br />
          {t('moto2.label')}
          <br />
          {t('moto3.label')}
        </div>
      </div>
      <div className={styles.middleContent}>
        <div>@WEAREINVICTUS</div>
        <div>
          <a href="https://www.facebook.com/InvictusGamesFoundation" title="facebook">
            <Facebook />
          </a>
          <a href="https://x.com/weareinvictus" title="twitter">
            <X />
          </a>
          <a href="https://www.instagram.com/weareinvictusgames/" title="instagram">
            <Instagram />
          </a>
          <a href="https://www.youtube.com/user/invictuslondon" title="youtube">
            <Youtube />
          </a>
        </div>
        <div>{t('footerFollow.label')}</div>
      </div>
      {FEATURE_FLAGS.storeLinks && (
        <div className={styles.storeLinks}>
          <a
            href="https://apps.apple.com/gb/app/we-are-invictus/id1471377135?mt=8"
            target="_blank"
            rel="noopener noreferrer">
            <AppStore />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=co.flowmo.wis&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
            target="_blank"
            rel="noopener noreferrer">
            <PlayStore />
          </a>
        </div>
      )}
      <div className={styles.languageSelect}>
        <LanguageSelector />
      </div>
      <div className={styles.bottomContent}>
        <div className={styles.bottomLeft}>
          {t('footer1.label')}
          <br />
          {t('footer2.label')}
          <br />
          {t('footer3.label')}
          <br />
          {t('footer4.label')}
        </div>
        <div className={styles.bottomRight}>
          <a
            className={styles.link}
            href="https://invictusgamesfoundation.org/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer">
            {t('privacyNotice.label')}
          </a>
          <br />
          <a
            className={styles.link}
            href="https://weareinvictus.co.uk/eula"
            target="_blank"
            rel="noreferrer">
            {t('licenceAgreement.label')}
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
