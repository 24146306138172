import React, { Fragment, useEffect, useRef } from 'react';
import JsVideoPlayer from 'react-video-js-player'; // don't remove handles asset imports!
import videojs from 'video.js';


const VideoPlayer = (props) => {
  const [player, setPlayer] = React.useState();
  const videoEl = useRef(null);
  const defaultOptions = {
    fluid: true,
    autoplay: false,
    controls: true,
    html5: {
      vhs: { withCredentials: true },
    },
  };

  useEffect(() => {
    const video = videojs(videoEl.current, { ...defaultOptions, ...props });
    setPlayer(video);
    return () => video.dispose();
  }, []);

  const handleClick = (e) => {
    const { autoplay } = props;
    if (player && autoplay) {
      if (player.paused()) {
        player.play();
      } else {
        player.pause();
      }
      e.stopPropagation();
    }
  };

  return (
    <Fragment>
      <video
        className={[
          'video-js',
          'vjs-big-play-centered',
        ].join(' ')}
        ref={videoEl}
        onClick={handleClick}
      />
    </Fragment>
  );
};

export default VideoPlayer;
