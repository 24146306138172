var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var BLOCK_DISPATCH = 'WIS/blocked/BLOCK_DISPATCHED';
export var BLOCK_FULFILL = 'WIS/blocked/BLOCK_FULFILLED';
export var BLOCK_REJECT = 'WIS/blocked/BLOCK_REJECTED';
export var UNBLOCK_DISPATCH = 'WIS/blocked/UNBLOCK_DISPATCHED';
export var UNBLOCK_FULFILL = 'WIS/blocked/UNBLOCK_FULFILLED';
export var UNBLOCK_REJECT = 'WIS/blocked/UNBLOCK_REJECTED';
export var UPDATE_BLOCKED_USERS = 'WIS/blocked/UPDATE_BLOCKED_USERS';
export var UPDATE_BLOCKED_USERS_ERROR = 'WIS/blocked/UPDATE_BLOCKED_USERS_ERROR';
export var block = function (uid) { return ({ type: BLOCK_DISPATCH, uid: uid }); };
export var blockFulfill = function (users) { return ({ type: BLOCK_FULFILL, users: users }); };
export var blockReject = function (error) { return ({ type: BLOCK_REJECT, error: error }); };
export var unBlock = function (uid) { return ({ type: UNBLOCK_DISPATCH, uid: uid }); };
export var unBlockFulfill = function (users) { return ({ type: UNBLOCK_FULFILL, users: users }); };
export var unBlockReject = function (error) { return ({ type: UNBLOCK_REJECT, error: error }); };
export var updateBlockedUsers = function (users) { return ({ type: UPDATE_BLOCKED_USERS, users: users }); };
export var updateBlockedUsersError = function (error) { return ({ type: UPDATE_BLOCKED_USERS_ERROR, error: error }); };
var initialState = {
    users: [],
    updating: false,
    error: null,
};
export default function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    switch (action.type) {
        case BLOCK_DISPATCH:
            return __assign(__assign({}, state), { updating: true, error: null });
        case BLOCK_FULFILL:
            return __assign(__assign({}, state), { updating: false, users: action.users, error: null });
        case BLOCK_REJECT:
            return __assign(__assign({}, state), { updating: false, error: action.error });
        case UNBLOCK_DISPATCH:
            return __assign(__assign({}, state), { updating: true, error: null });
        case UNBLOCK_FULFILL:
            return __assign(__assign({}, state), { updating: false, users: action.users, error: null });
        case UNBLOCK_REJECT:
            return __assign(__assign({}, state), { updating: false, error: action.error });
        case UPDATE_BLOCKED_USERS:
            return __assign(__assign({}, state), { users: action.users });
        case UPDATE_BLOCKED_USERS_ERROR:
            return __assign(__assign({}, state), { error: action.error });
        default:
            return state;
    }
}
