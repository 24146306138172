import auth, { LOGOUT_FULFILL } from './auth';
import blocked from './blocked';
import boards from './board';
import chat from './chat';
import comments from './comment';
import files from './files';
import flag from './flag';
import liked from './liked';
import notifications from './notifications';
import posts from './post';
import profile from './profile';
import saved from './saved';
import signUp from './signUp';
import taxonomyCountry from './taxonomyCountry';
import taxonomyFlagReasons from './taxonomyFlagReasons';
import taxonomyGameInvolvement from './taxonomyGameInvolvement';
import taxonomyGender from './taxonomyGender';
import taxonomyMedicalStatus from './taxonomyMedicalStatus';
import taxonomyMilitaryService from './taxonomyMilitaryService';
import taxonomyMilitaryStatus from './taxonomyMilitaryStatus';
import taxonomyOpportunities from './taxonomyOpportunities';
import taxonomyPastGames from './taxonomyPastGames';
import taxonomyPastTrials from './taxonomyPastTrials';
import postCategories from './postCategories';
import { combineReducers } from 'redux';
// import taxonomyEthnicity from './taxonomyEthnicity';
export var appReducer = combineReducers({
    auth: auth,
    signUp: signUp,
    profile: profile,
    notifications: notifications,
    chat: chat,
    posts: posts,
    postCategories: postCategories,
    comments: comments,
    blocked: blocked,
    saved: saved,
    liked: liked,
    flag: flag,
    files: files,
    // taxonomyEthnicity,
    taxonomyGender: taxonomyGender,
    taxonomyCountry: taxonomyCountry,
    taxonomyMilitaryStatus: taxonomyMilitaryStatus,
    taxonomyMilitaryService: taxonomyMilitaryService,
    taxonomyPastGames: taxonomyPastGames,
    taxonomyPastTrials: taxonomyPastTrials,
    taxonomyMedicalStatus: taxonomyMedicalStatus,
    taxonomyOpportunities: taxonomyOpportunities,
    taxonomyGameInvolvement: taxonomyGameInvolvement,
    taxonomyFlagReasons: taxonomyFlagReasons,
    boards: boards,
});
export var rootReducer = function (state, action) {
    if (action.type === LOGOUT_FULFILL) {
        // state = undefined;
        window.location.reload();
    }
    return appReducer(state, action);
};
