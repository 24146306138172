// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.-Modal-module-backdrop-OmOx_{position:fixed;top:0px;bottom:0px;left:0px;right:0px;background-color:rgba(0,0,0,.75);padding:16px;z-index:250;display:flex;align-items:center;justify-content:center}.-Modal-module-backdrop-OmOx_ .-Modal-module-modal-ufBCd{width:100%;max-width:350px}`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.module.scss"],"names":[],"mappings":"AAAA,8BACE,cAAA,CACA,OAAA,CACA,UAAA,CACA,QAAA,CACA,SAAA,CACA,gCAAA,CACA,YAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CAEA,yDACE,UAAA,CACA,eAAA","sourcesContent":[".backdrop {\n  position: fixed;\n  top: 0px;\n  bottom: 0px;\n  left: 0px;\n  right: 0px;\n  background-color: rgba(0,0,0,0.75);\n  padding: 16px;\n  z-index: 250;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  .modal {\n    width: 100%;\n    max-width: 350px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `-Modal-module-backdrop-OmOx_`,
	"modal": `-Modal-module-modal-ufBCd`
};
export default ___CSS_LOADER_EXPORT___;
