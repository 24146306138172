import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import usePostSave from '../../hooks/usePostSave';
import UIListItem from '../UIListItem/UIListItem';
import styles from './SavePostListItem.module.scss';


const SavePostListItem = ({ postId }) => {
  const {
    toggleSaved,
    isSaved,
    isSaving,
    optimisticSaved,
  } = usePostSave(postId);

  const { t } = useTranslation();
  const saveStatus = isSaving ? optimisticSaved : isSaved;
  const [label, setLabel] = useState(t('addSavedList.label'));
  useEffect(() => {
    if (isSaving) {
      setLabel(t('updatingSavedList.label'));
    } else if (saveStatus) {
      setLabel(t('removeSavedList.label'));
    } else {
      setLabel(t('addSavedList.label'));
    }
  }, [isSaving, saveStatus]);

  const iconStyles = [
    styles.icon,
    isSaving && styles.saving,
  ].join(' ');

  const icon = isSaving
    ? <FontAwesomeIcon className={iconStyles} icon={['far', 'hourglass']} />
    : <FontAwesomeIcon className={iconStyles} icon={[saveStatus ? 'fas' : 'far', 'bookmark']} />;

  return (
    <UIListItem
      icon={icon}
      disabled={isSaving}
      onClick={toggleSaved}
    >
      {label}
    </UIListItem>
  );
};

SavePostListItem.propTypes = {
  postId: PropTypes.string.isRequired,
};

export default SavePostListItem;
