import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  postUnpublishDispatch,
} from '../redux/modules/post';


const mapStateToProps = ({ posts }) => ({
  isUnpublishing: posts.isUnpublishing,
  unpublishedSinceLastFetched: posts.unpublishedSinceLastFetched,
  unPublishError: posts.unPublishError,
});

function usePostRemove(postId) {
  const dispatch = useDispatch();
  const {
    isUnpublishing,
    unpublishedSinceLastFetched,
    unPublishError,
  } = useSelector(mapStateToProps);
  const [removed, setIsRemoved] = useState(false);
  const [optimisticRemove, setOptimisticRemoved] = useState(false);

  useEffect(() => {
    const isRemoved = unpublishedSinceLastFetched?.includes(postId);
    setIsRemoved(isRemoved);
  }, [postId, unpublishedSinceLastFetched]);

  useEffect(() => setOptimisticRemoved(false), [unPublishError]);

  const removePost = () => {
    setOptimisticRemoved(true);
    dispatch(postUnpublishDispatch(postId));
  };

  return {
    removePost,
    removed,
    isRemoving: isUnpublishing,
    optimisticRemove,
  };
}

export default usePostRemove;
