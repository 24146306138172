var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loader/Loader';
import { Preferences } from '@capacitor/preferences';
// import { RootState } from '../../redux/modules/root';
// import { getChatRoomsDispatch } from '../../redux/modules/chat';
import s from './ChatRoomList.module.scss';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import api from '../../util/api';
import { useChatRooms, useMessages } from '../../util/APIDjango';
import ChatModal from '../ChatModal/ChatModal';
import { PageTitle } from '../PageTitle/PageTitle';
// const mapStateToProps = ({ chat }: RootState) => ({
//   rooms: chat.rooms,
//   loading: chat.isFetching,
//   error: chat.fetchError,
// });
/**
 * Lists all available Chat Rooms
 * @returns {JSX.Element}
 */
var ChatRoomList = function () {
    // const { rooms, loading, error } = useSelector(mapStateToProps);
    var _a = useChatRooms(), chatRoomsData = _a[0], setChatRoomsData = _a[1];
    var fetchError = chatRoomsData.fetchError, isFetching = chatRoomsData.isFetching, rooms = chatRoomsData.rooms;
    var chatId = useParams().chatId;
    var history = useHistory();
    var t = useTranslation().t;
    // const dispatch = useDispatch();
    var _b = useState(true), autoTranslateStatus = _b[0], setAutoTranslateStatus = _b[1];
    var i18n = useTranslation('', { useSuspense: false }).i18n;
    var _c = useState(i18n.language), language = _c[0], setLanguage = _c[1];
    var _d = useMessages(), messagesData = _d[0], setMessagesData = _d[1];
    var messagesByRoom = messagesData.messagesByRoom;
    var likedByMe = messagesByRoom.likedByMe, results = messagesByRoom.results;
    var getChatRooms = function () {
        return setChatRoomsData(__assign(__assign({}, chatRoomsData), { isFetched: false, isFetching: true }));
    };
    var getChatRoomsFulfil = function (data) {
        return setChatRoomsData(__assign(__assign({}, chatRoomsData), { rooms: data, isFetching: false, isFetched: true, fetchError: null }));
    };
    var getChatRoomsReject = function (error) {
        return setChatRoomsData(__assign(__assign({}, chatRoomsData), { fetchError: error, isFetching: false }));
    };
    useEffect(function () {
        void Preferences.get({ key: 'autoTranslateStatus' }).then(function (result) {
            if (!result.value) {
                setAutoTranslateStatus(false);
            }
        });
        // dispatch(getChatRoomsDispatch());
        getChatRooms();
        api
            .chatRooms()
            .then(function (chatRoomsData) {
            getChatRoomsFulfil(chatRoomsData);
        })
            .catch(function (err) {
            getChatRoomsReject(err.message);
        });
    }, []);
    useEffect(function () {
        var handleLanguageChanged = function (lang) {
            setLanguage(lang);
        };
        i18n.on('languageChanged', handleLanguageChanged);
        return function () {
            i18n.off('languageChanged', handleLanguageChanged);
        };
    }, [i18n]);
    if (isFetching) {
        return _jsx(Loader, {});
    }
    var resetChatMessages = function () {
        setMessagesData({
            fetchError: null,
            isFetched: false,
            isFetching: false,
            lastMessageIdFetched: null,
            messagesByRoom: {
                likedByMe: [],
                next: null,
                previous: null,
                results: [],
            },
            messagesFetched: false,
            messagesFetching: false,
        });
    };
    return (_jsxs("div", { children: [_jsxs(_Fragment, { children: [fetchError && _jsx("p", __assign({ className: s.error }, { children: fetchError })), _jsx(PageTitle, { title: "Chat" }), _jsx("h2", __assign({ className: s.subtitle }, { children: t('weAreInvictusChatRooms.label') })), _jsx("ul", __assign({ className: s.chatList }, { children: rooms &&
                            rooms.map(function (room) {
                                return (room === null || room === void 0 ? void 0 : room.id) && (_jsx("li", __assign({ className: s.chatListItem, onClick: function () {
                                        history.push("/chat/".concat(room === null || room === void 0 ? void 0 : room.id));
                                        resetChatMessages();
                                    } }, { children: _jsxs("div", __assign({ className: s.titles }, { children: [_jsx("h2", __assign({ className: s.name }, { children: language !== 'en' && autoTranslateStatus && (room === null || room === void 0 ? void 0 : room.translatedName)
                                                    ? room === null || room === void 0 ? void 0 : room.translatedName
                                                    : room.name })), _jsx("h3", __assign({ className: s.description }, { children: language !== 'en' && autoTranslateStatus && (room === null || room === void 0 ? void 0 : room.translatedDescription)
                                                    ? room === null || room === void 0 ? void 0 : room.translatedDescription
                                                    : room.description }))] })) }), room.id));
                            }) }))] }), chatId && _jsx(ChatModal, { chatId: Number(chatId) })] }));
};
export default ChatRoomList;
