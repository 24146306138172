var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import useUserBlock from '../../hooks/useUserBlock';
var CommentMention = function (_a) {
    var user = _a.user, props = __rest(_a, ["user"]);
    var isBlocked = useUserBlock(user === null || user === void 0 ? void 0 : user.id).isBlocked;
    var t = useTranslation().t;
    return (_jsx(_Fragment, { children: _jsx("span", __assign({}, props, { children: isBlocked || !(user === null || user === void 0 ? void 0 : user.username) ? t('blockedUser.label') : "@".concat(user.username) })) }));
};
export default CommentMention;
